import { Grid, Skeleton, Typography } from '@mui/material'
import { useMemo } from 'react'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { DashboardAverage, Empty } from '..'
import { getAreas } from '../../helpers/utils'
import useDashboardContext from '../../hooks/useDashboardContext'
import ChartLegend from '../tracker-chart/chart-legend'
import { initialOptions } from '.'

const chartHeight = 250

const DriversBarChart = ({ parsedAreas, loading, empty, chartOptions, setChartOptions }) => {
  const { isOrgProfile } = useDashboardContext()
  if (loading) {
    return <Grid display='flex' width='100%' justifyContent='center'><Skeleton variant='rectangular' width='100%' height={chartHeight} /></Grid>
  }
  if (empty) {
    return <Empty message={!!isOrgProfile ? 'No data to display. This is because not enough people have provided results for the filtered criteria' : 'There is no data to display'} />
  }

  return (
    <Grid width='100%'>
      <ChartLegend chartOptions={chartOptions} setChartOptions={setChartOptions} />
      <ResponsiveContainer width='100%' height={chartHeight}>
        <BarChart
          layout='vertical'
          data={parsedAreas}
        >
          <Tooltip />
          <CartesianGrid horizontal={false} />
          <XAxis type="number" ticks={[1, 2, 3, 4, 5, 6]} tickCount={0} />
          <YAxis tick={false} type="category" scale="band" dataKey='name' />
          {initialOptions.map(({ key, color }) => (
            <Bar key={key} dataKey={key} stackId='a' barSize={30} fill={color} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Grid >
  )
}

const DriverScores = ({ parsedAreas, loading, empty }) => {
  const { isOrgProfile } = useDashboardContext()
  if (loading) {
    return (
      <Grid display='flex' flexWrap='wrap' width='100%' height='100%' justifyContent='space-between' padding={2}>
        <Skeleton variant='rectangular' width='45%' height={100} />
        <Skeleton variant='rectangular' width='45%' height={100} />
        <Skeleton variant='rectangular' width='45%' height={100} />
        <Skeleton variant='rectangular' width='45%' height={100} />
      </Grid>
    )
  }
  if (empty) {
    return <Empty message={!!isOrgProfile ? 'No data to display. This is because not enough people have provided results for the filtered criteria' : 'There is no data to display'} />
  }

  return (
    <Grid container width='100%'>
      {parsedAreas.map(({ key, name, value }) => (
        <Grid key={key} xs={5} margin={1}>
          <DashboardAverage title={name} description='' type='score' value={value} />
        </Grid>
      ))}
    </Grid>
  )
}

export const RenderDrivers = ({ chartOptions, setChartOptions }) => {
  const { viewLoading, dashboardData, isIndividualView } = useDashboardContext()
  const areas = useMemo(() => getAreas(dashboardData.evaluationReportResult, true), [dashboardData])
  const parsedAreas = useMemo(() => {
    if (!areas) return []
    const possibleFilters = chartOptions.filter(i => i.isSelected)
    let result = Object.entries(areas)
    if (possibleFilters.length) {
      result = result.filter(([k]) => possibleFilters.find(j => j.key === k))
    }
    return result.map(([k, v]) => ({
      key: k,
      name: v.title,
      value: Number.parseFloat(v.score),
      [k]: v.score
    }))
  }, [areas, chartOptions])

  const shouldShowEmpty = useMemo(() => {
    if (!areas || !Object.keys(areas).length || !dashboardData?.evaluationReportResult) return true
    return false
  }, [areas, dashboardData])

  return (
    <>
      <Grid display='flex'>
        <Grid width='45%'>
          <Typography variant='h5'>Organisational Drivers Average</Typography>
          <DriverScores parsedAreas={parsedAreas} loading={viewLoading} empty={shouldShowEmpty} />
        </Grid>
        <Grid width='55%'>
          <Typography variant='h5'>Organisation Drivers Average Score</Typography>
          <DriversBarChart parsedAreas={parsedAreas} loading={viewLoading} empty={shouldShowEmpty} chartOptions={chartOptions} setChartOptions={setChartOptions} />
        </Grid>
      </Grid>
      {!isIndividualView && dashboardData?.evaluationTotalUsers && !viewLoading
        ? (
          <Grid display='flex' justifyContent='flex-end' paddingRight={5}>
            <Typography>{dashboardData?.evaluationTotalUsers} results for the filtered criteria</Typography>
          </Grid>
        ) : null}
    </>
  )
}
