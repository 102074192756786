import React, { useMemo } from 'react'
import { Drawer, Divider } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ReMenu, Empty, Loading } from '../../components'
import { userSlice } from '../../reducers'
import TrackTodayButton from '../track-today-button'
import ability from '../../config/ability'
import { useStyles } from './style'
import { featureSelector } from '../../reducers/user/selectors'
import { useEventContext } from '../../contexts/event'

const LeftDrawer = () => {
  const classes = useStyles()
  const params = useParams()
  const events = useEventContext()
  const user = useSelector(userSlice.selectors.selectUser)
  const selectedView = useSelector(userSlice.selectors.selectedView)
  const features = useSelector(featureSelector)
  const isIndividualView = (selectedView && selectedView.type === 'individual') || !selectedView

  const menu1 = useMemo(() => {
    const isSuperAdmin = ability.can('view', 'superAdminView')
    const baseMenu = [{ name: 'Resilience Dynamic Dashboard®', path: '' }]
    if (isSuperAdmin) baseMenu.push({ name: 'Organisations', path: 'organisations' })
    if (isSuperAdmin) baseMenu.push({ name: 'Plans', path: 'plans' })
    if (!isIndividualView) baseMenu.push({ name: 'Cohorts', disabled: false, path: 'cohorts', 'data-test': 'menu-button-cohorts' })
    if (!isIndividualView) baseMenu.push({ name: 'Groups', disabled: false, path: 'teams', 'data-test': 'menu-button-teams' })

    return baseMenu
  }, [isIndividualView])

  const menu2 = useMemo(() => {
    const baseMenu = []
    if (features.RESILIENCE_EVALUATION) baseMenu.push({ name: 'Resilience Dynamic® Questionnaire', path: 'evaluation-results' })
    if (features.ORGANISATION_DRIVERS) baseMenu.push({ name: 'Organisation Drivers', path: 'drivers', onboardingClass: 'onboarding-organisation-drivers' })
    if (features.RESILIENCE_INDICATOR) baseMenu.push({ name: 'Resilience Dynamic® Indicator', path: 'indicator-results' })
    if (isIndividualView && features.FUNDAMENTALS) baseMenu.push({ name: 'Resilience Fundamentals', path: 'checkin-checkout/fundamentals', onboardingClass: 'onboarding-fundamentals' })
    if (isIndividualView && features.CHECKIN_CHECKOUT) baseMenu.push({ name: 'Checkin Checkout', path: 'checkin-checkout/cico-main', onboardingClass: 'onboarding-checkin-checkout' })
    if (isIndividualView && features.RESILIENCE_TOOLKIT) baseMenu.push({ name: 'Resilience Toolkit', externalurl: `${process.env.REACT_APP_TOOLKIT_URL || 'https://toolkit.resilienceengine.com'}/?tenant=${params.tenantKey}`, onboardingClass: 'onboarding-resilience-toolkit' })
    return baseMenu
  }, [features, isIndividualView, params.tenantKey])

  const menu3 = useMemo(() => {
    const isSuperAdminOrOrgView = ability.can('view', 'superAdminView') || ability.can('view', 'OrgView')
    const baseMenu = []
    if (isIndividualView) baseMenu.push({ name: 'Profile Settings', path: 'profile' })
    if (isIndividualView && (features.RESILIENCE_EVALUATION || features.RESILIENCE_INDICATOR || features.RESILIENCE_TRACKER)) {
      baseMenu.push({ name: 'My Reports and Notes', disabled: false, path: 'reports', onboardingClass: 'onboarding-my-reports-and-notes' })
    }
    baseMenu.push({ name: 'Help', path: 'help' })
    if (isSuperAdminOrOrgView && isIndividualView) baseMenu.push({ name: 'Cycle', disabled: false, path: 'cycle' })
    return baseMenu
  }, [features, isIndividualView])

  const userViews = user.views && user.views.length > 0 ? [
    {
      name: user.client.name,
      icon: isIndividualView ? user?.profilePic?.url : user.client.icon,
      items: user.views.map(view => ({ ...view, 'data-test': `button-${view.type}-${view.id}`, icon: view.type === 'individual' ? user?.profilePic?.url : view.icon }))
    }
  ] : []

  return (
    <Drawer
      className={classes.drawer}
      variant='permanent'
      classes={{
        paper: classes.drawerPaper
      }}
      anchor='left'
    >
      <div className={classes.content}>
        <div className={classes.toolbarIcon}>
          <div className={classes.box}>
            <img
              src='/img/logo-v2.svg'
              alt='Resilience Engine Logo'
              className={classes.image}
            />
          </div>
        </div>
        <div className={classes.paddingTop} />
        <TrackTodayButton />
        {userViews.length > 0 && (
          <>
            <ReMenu menu={userViews} />
            <Divider />
          </>
        )}
        <ReMenu menu={menu1} />
        {menu2.length > 0 && <Divider />}
        {menu2.length > 0 && <ReMenu menu={menu2} />}
        {events.getUser.isWorking && menu2.length === 0 && <Loading />}
        {isIndividualView && !events.getUser.isWorking && menu2.length === 0 && <Empty message='You must be included in a cohort to see your features' alt='No features enabled' column />}
        <Divider />
        <ReMenu menu={menu3} />
        <Divider />
      </div>
    </Drawer>
  )
}

export default LeftDrawer
