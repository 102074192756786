import moment from 'moment'
import { SUPER_ADMIN_EMAIL, SUPER_ADMIN_DOMAIN } from '../helpers/consts'

const isNullOrUndefined = value => value === null || value === undefined

const getRange = score => {
  let range = '0 - 1.4'
  if (score >= 5.5) {
    range = '5.5 - 6'
  } else if (score >= 5 && score <= 5.49) {
    range = '5.0 - 5'
  } else if (score >= 4.5 && score <= 4.99) {
    range = '4.5 - 4.9'
  } else if (score >= 3.9 && score <= 4.49) {
    range = '3.9 - 4.4'
  } else if (score >= 3.4 && score <= 3.89) {
    range = '3.4 - 3.8'
  } else if (score >= 2.9 && score <= 3.39) {
    range = '2.9 - 3.3'
  } else if (score >= 2.5 && score <= 2.89) {
    range = '2.5 - 2.8'
  } else if (score >= 1.5 && score <= 2.49) {
    range = '1.5 - 2.4'
  }
  return range
}

const progressReducer = (acc, cur) => acc + cur.progress || 0
const roundNumber = (number) => Math.round((number + Number.EPSILON) * 100) / 100

const getOrgDriversRange = (driverAnswers, area) => {
  const range = {}
  const rangeArea = driverAnswers.filter(answer => answer.question.area === area)
  rangeArea.forEach(answer => {
    if (!range.low || (answer.driverScore < range.low)) range.low = answer.driverScore
    if (!range.high || (answer.driverScore > range.high)) range.high = answer.driverScore
  })
  return `${range.low} - ${range.high}`
}

const getQuestionsByArea = (answers, area, isOrgProfile) => {
  return answers.filter(answer => answer.question.area === area)
    .map(answer => {
      return { score: answer.driverScore, question: answer.question.text }
    })
  // return answers.filter(answer => answer.area === area)
}

const getAreas = (userResults, isOrgProfile = false) => {
  const driverAnswers = userResults.driverAnswers
  if (!driverAnswers) return {}
  return {
    psychological_safety: {
      title: 'Psychological Safety',
      score: userResults.driverScores.psychological_safety,
      description: '',
      range: getOrgDriversRange(driverAnswers, 'psychological_safety'),
      questions: getQuestionsByArea(driverAnswers, 'psychological_safety', isOrgProfile)
    },
    workload_stress: {
      title: 'Workload & Stress',
      score: userResults.driverScores.workload_stress,
      description: '',
      range: getOrgDriversRange(driverAnswers, 'workload_stress'),
      questions: getQuestionsByArea(driverAnswers, 'workload_stress', isOrgProfile)
    },
    trust: {
      title: 'Trust',
      score: userResults.driverScores.trust,
      description: '',
      range: getOrgDriversRange(driverAnswers, 'trust'),
      questions: getQuestionsByArea(driverAnswers, 'trust', isOrgProfile)
    },
    engagement: {
      title: 'Engagement',
      score: userResults.driverScores.engagement,
      description: '',
      range: getOrgDriversRange(driverAnswers, 'engagement'),
      questions: getQuestionsByArea(driverAnswers, 'engagement', isOrgProfile)
    }
  }
}

const namesResilienceGraph = [
  { name: 'demand', color: '#6f61a6', isSelected: false, values: [] },
  { name: 'capacity', color: '#b168a1', isSelected: false, values: [] },
  { name: 'energy', color: '#5EBED0', isSelected: false, values: [] }
]

const namesWellbeingGraphData = [
  { name: 'sleep', color: '#5B4A99', isSelected: false, values: [] },
  { name: 'stress', color: '#BF347C', isSelected: false, values: [] },
  { name: 'wellbeing', color: '#6CADC0', isSelected: false, values: [] },
  { name: 'contentedness', color: '#868B92', isSelected: false, values: [] }
]

const getDatesByRange = (range = 'weekly') => {
  return {
    start: moment().utc().startOf('day').subtract(7, 'days'),
    end: moment().utc().endOf('day')
  }
}

const rangeDate = ({ start, end }) => {
  let fromDate = moment(start)
  const toDate = moment(end)
  // const diff = toDate.diff(fromDate, 'days')
  const range = []
  // for (let i = 0; i <= diff; i++) {
  //   const clone = moment(start, 'YYYY-MM-DD').add(i, 'days')
  //   range = [...range, clone]
  // }
  while (fromDate.isSameOrBefore(toDate)) {
    range.push(fromDate.format('YYYY-MM-DD'))
    fromDate = moment(fromDate).add(1, 'days')
  }
  return range
}

const parseChartData = (data, type) => {
  const dayData = []
  const floatVariability = type === 'resilience' ? 0.08 : 0.06
  let aux = -floatVariability

  const graphData = data.map(line => {
    const values = line.values.map(value => {
      const date = moment(value.date)
      let updatedScore = 0
      if (value.score > 0) {
        const graphScore = dayData[`${type}-${date.format('YYYY-MM-DD')}`]
        if (graphScore && graphScore.indexOf(value.score) > -1) {
          aux = graphScore.length * floatVariability * (Math.pow(-1, graphScore.length))
          updatedScore = value.score + parseFloat(aux)
          dayData[`${type}-${date.format('YYYY-MM-DD')}`].push(value.score)
        } else {
          updatedScore = value.score
          if (dayData[`${type}-${date.format('YYYY-MM-DD')}`] === undefined) {
            dayData[`${type}-${date.format('YYYY-MM-DD')}`] = []
          }
          dayData[`${type}-${date.format('YYYY-MM-DD')}`].push(value.score)
        }
      }
      return {
        date: new Date(date),
        score: updatedScore // resultDay.length ? resultDay[0].answer : 0
      }
    })
    return { ...line, values }
  })
  return graphData
}

const getChartData = (data, type, dates, view) => {
  const names = type === 'resilience' ? namesResilienceGraph : namesWellbeingGraphData
  const rangeDates = rangeDate(dates)
  const graphData = names.map(label => {
    const values = rangeDates.map(date => {
      date = moment(date)
      const resultDay = data.filter(answer => {
        return isEqualDate(answer.createdAt, date) && (answer.question ? answer.question.name === label.name : answer.questionName === label.name)
      })
      // if (resultDay && resultDay.length > 0 && label.name === 'capacity') {
      //   console.log('log -- ', Math.round(resultDay[0]))
      // }
      return {
        date: new Date(date),
        score: resultDay.length ? Number.parseFloat(resultDay[0].answer) : 0
      }
    })
    return { ...label, values }
  })
  if (['week', 'month'].includes(view)) {
    return parseChartData(formatChartData(graphData, view), type)
  }
  console.log(parseChartData(graphData, type))
  return parseChartData(graphData, type)
}

const formatChartData = (graphData, view) => {
  switch (view) {
    case 'week':
      return formatDataByWeek(graphData)
    case 'month':
      return formatDataByMonth(graphData)
    default:
      return graphData
  }
}

const formatDataByWeek = (graphData) => {
  const graphDataByWeek = graphData.map(label => {
    const byWeekEntries = []
    label.values.forEach(value => {
      const week = moment(value.date).week()
      if (!byWeekEntries[week]) {
        byWeekEntries[week] = []
      }
      byWeekEntries[week].push(value)
    })
    const formatValues = []
    byWeekEntries.forEach((week, weekNumber) => {
      const weekMonday = moment().day('Monday').week(weekNumber).toDate()
      const validEntries = week.filter(value => value.score)
      if (!validEntries.length) {
        formatValues.push({ date: weekMonday, score: 0 })
      } else {
        const weekTotal = validEntries.reduce((acc, cur) => acc + cur.score, 0)
        formatValues.push({ date: weekMonday, score: (weekTotal / validEntries.length) })
      }
    })
    return { ...label, values: formatValues } //, byWeekEntries
  })
  return graphDataByWeek
}

const formatDataByMonth = (graphData) => {
  const graphDataByMonth = graphData.map(label => {
    const byMonthEntries = []
    label.values.forEach(value => {
      const month = moment(value.date).month()
      if (!byMonthEntries[month]) {
        byMonthEntries[month] = []
      }
      byMonthEntries[month].push(value)
    })
    const formatValues = []
    byMonthEntries.forEach((week, monthNumber) => {
      const firstDayMonth = moment().month(monthNumber).startOf('month').toDate()
      const validEntries = week.filter(value => value.score)
      if (!validEntries.length) {
        formatValues.push({ date: firstDayMonth, score: 0 })
      } else {
        const monthTotal = validEntries.reduce((acc, cur) => acc + cur.score, 0)
        formatValues.push({ date: firstDayMonth, score: (monthTotal / validEntries.length) })
      }
    })
    return { ...label, values: formatValues }
  })
  return graphDataByMonth
}

const isBetweenDate = (date, range) => {
  const { start, end } = getDatesByRange(range)
  return moment(date).isBetween(moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD'), undefined, '[]')
}

const isTodayDate = date => {
  return moment(date).isSame(moment(new Date()).format('YYYY-MM-DD'), 'day')
}

const isEqualDate = (date1, date2) => {
  return moment(date1).startOf('day').isSame(date2)
}

const parseDate = ({ start, end }) => {
  return {
    start: moment(start).startOf('day').toISOString(),
    end: moment(end).endOf('day').toISOString()
  }
}

const getResilienceAverage = (answers, { start, end }, view = 'individual') => {
  const types = ['demand', 'capacity', 'energy', 'cap-dem']

  const data = answers.filter(answer => {
    const questionName = answer.question ? answer.question.name : answer.questionName
    return types.includes(questionName) &&
      moment(answer.createdAt).isBetween(moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD'), 'days', '[]')
  })
  if (data.length === 0) {
    return data
  }

  const result = {}

  data.forEach(answer => {
    const questionName = answer.question ? answer.question.name : answer.questionName
    if (!result[questionName]) {
      result[questionName] = []
    }
    result[questionName].push(Math.round(answer.answer))
  })

  const reducer = (acc, cur) => acc + cur
  const demand = result.demand ? result.demand.reduce(reducer) / result.demand.length : 0
  const capacity = result.capacity ? result.capacity.reduce(reducer) / result.capacity.length : 0

  const valuesMap = {
    ...(result.demand && {
      demand: {
        title: view === 'individual' ? 'Average Resilience Demand' : 'Average Organisation  Resilience Demand',
        value: demand.toFixed(2),
        description: 'Your Average Resilience Demands taken from your Resilience Tracker inputs, based on the dates at the top of this section.'
      }
    }),
    ...(result.capacity && {
      capacity: {
        title: view === 'individual' ? 'Average Resilience Capacity' : 'Average Organisation Resilience Capacity',
        value: capacity.toFixed(2),
        description: 'Your Average Resilience Capacity taken from your Resilience Tracker inputs, based on the dates at the top of this section.'
      }
    }),
    ...(result.energy && {
      energy: {
        title: view === 'individual' ? 'Average Energy' : ' Average Organisation Energy',
        value: (result.energy.reduce(reducer) / result.energy.length).toFixed(2),
        description: 'Your Average Energy taken from your Resilience Tracker inputs, based on the dates at the top of this section.'
      }
    }),
    ...(result.capacity && result.demand && {
      'cap-dem': {
        title: view === 'individual' ? 'Capacity - Demand' : 'Average Organisation Capacity - Demand',
        value: (capacity - demand).toFixed(2),
        description: 'The Average Resilience Capacity minus the Average Resilience Demands, taken from your Resilience Tracker inputs, based on the dates at the top of this section.'
      }
    })
  }

  return types.map(type => {
    return {
      type,
      ...valuesMap[type]
    }
  })
}

const replaceIp = contentBody => {
  return contentBody.replaceAll('http://3.65.254.191/', 'https://toolkit.resilienceengine.dev/')
}

const checkSuperAdmin = (email) => {
  const domain = email.match(/@(\w+)/g) // first position
  if (!domain.length) return false
  return SUPER_ADMIN_EMAIL.includes(email) || SUPER_ADMIN_DOMAIN.includes(domain[0])
}

const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    )
}

const tryParseJson = (json) => {
  try {
    return JSON.parse(json)
  } catch (err) {
    return json
  }
}

function sendS3File (presignedPostUrl, fileContents, fileType) {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest()
    xhr.open('PUT', presignedPostUrl)
    xhr.setRequestHeader('Content-Type', 'image/jpeg')
    xhr.send(fileContents)
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response)
      } else {
        reject(new Error(xhr.statusText))
      }
    }
    xhr.onerror = function () {
      reject(new Error(xhr.statusText))
    }
  })
}

const getResilienceValues = result => {
  const types = ['score', 'level', 'range', 'variability'] //, 'notes'
  const valuesMap = {
    score: {
      title: 'Resilience Score',
      value: result.score,
      description: `Your Resilience Score is the average score calculated 
      from all of the answers given, including the variability, from within the 
      Resilience Dynamic® Questionnaire. This score is out of 6.`
    },
    level: {
      title: 'Resilience Level',
      value: result.level,
      description: `Your Resilience Level is the mapping of your 
      Resilience Score on the Resilience Dynamic®. There are 9 possible 
      Resilience Levels ranging from Breakdown through to Breakthrough. `
    },
    range: {
      title: 'Resilience Range',
      value: result.range,
      description: `Your Resilience Range is the average low score to 
      the average high score entered in the Resilience Dynamic® Questionnaire.`
    },
    variability: {
      title: 'Average Variability',
      value: result.averageVariance,
      description: `Your Average Variability is the average over the 
      24 questions in the Resilience Dynamic® Questionnaire. It indicates the 
      movement of your overall resilience.`
    }
  }

  return types.map(type => {
    return {
      type,
      ...valuesMap[type]
    }
  })
}

const encodeTrackerStatus = value => {
  if (!value.on) return 1
  if (value.repeat === 'daily') return 2
  return 3
}

export {
  roundNumber,
  encodeTrackerStatus,
  isNullOrUndefined,
  getRange,
  getDatesByRange,
  isBetweenDate,
  namesResilienceGraph,
  namesWellbeingGraphData,
  getChartData,
  isTodayDate,
  parseDate,
  getResilienceAverage,
  replaceIp,
  checkSuperAdmin,
  validateEmail,
  tryParseJson,
  sendS3File,
  progressReducer,
  getAreas,
  getResilienceValues
}
