import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import useNavigation from '../../../helpers/useNavigation'
import { CustomOverlay } from '../../../components'
import { useAlert } from '../../../hooks'
import OrgForm from '../components/form'
import moment from 'moment'
import Forbidden from '../../403'
import ability from '../../../config/ability'

const OrganisationEditPage = ({ onClick }) => {
  const navigate = useNavigation()
  const { orgId } = useParams()
  const [org, setOrg] = useState()
  const { showSuccessAlert, showErrorAlert } = useAlert()

  const [editOrg] = useCorvusEventList([{
    eventName: 'Organisation Was Edited',
    onSuccess: () => {
      showSuccessAlert('Success!', 'Organisation information was edited')
      navigate('/dashboard/organisations')
    },
    onError: (error) => {
      showErrorAlert('Error!', 'An unexpected error has occurred')
      console.log(error)
    }
  }])

  const [orgInfoWasRequested] = useCorvusEventList([{
    eventName: 'Organisation Info Was Requested',
    onSuccess: (org) => {
      org.licenses.forEach(license => {
        license.startDate = moment(license.startDate)
        license.endDate = moment(license.endDate)
      })
      setOrg(org)
      console.log(org)
    }
  }])

  useEventsOnViewLoad(() => {
    if (orgId) {
      orgInfoWasRequested.trigger({ orgId })
    }
  }, [orgId, orgInfoWasRequested])

  const handleCancelButton = () => {
    navigate('/dashboard/organisations')
  }

  const onSubmit = (org, licenses) => {
    editOrg.trigger({ org, licenses })
  }

  if (!org || orgInfoWasRequested.isWorking) {
    return <CustomOverlay active={orgInfoWasRequested.isWorking} text='Loading organisation information' />
  }

  const isSuperAdmin = ability.can('view', 'superAdminView')

  return (
    !isSuperAdmin ? <Forbidden />
      : <OrgForm
        isEdit
        onSubmit={onSubmit}
        onCancel={handleCancelButton}
        loadingMessage='Editing Organisation Information...'
        loadingView={editOrg.isWorking}
        defaultValues={org}
      />
  )
}
export default OrganisationEditPage
