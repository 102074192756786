import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7)
    },
    button: {
        color: theme.palette.secondary.dark,
        fill: theme.palette.secondary.dark
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: '54px'
    },
    previousButton: {
        color: theme.palette.button.default,
        border: '2px solid #465270',
        textTransform: 'none',
        fontWeight: 700,
        fontSize: 14,
        minWidth: '145px',
        padding: '8px 28px',
        '&:hover': {
            border: '2px solid #465270'
        }
    },
    footerLink: {
        marginRight: '16px'
    },
    footerButton: {
        marginLeft: '16px'
    },
    title: {
        margin: '32px 0 18px'
    },
    description: {
        marginBottom: '16px',
        fontSize: '16px',
        fontWeight: 400
    },
    empDescription: {
        fontSize: '16px',
        fontWeight: 700
    },
    modal: {
        borderRadius: '50px', color: 'red'
    },
    main: {
        width: '940px',
        padding: 20
    },
    resultHeader: {
        fontSize: '20px',
        fontWeight: 700,
        color: theme.palette.primary.dark,
        marginBottom: '16px'
    },
    resultContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    field: {
        width: '300px',
        padding: '10px',
        textAlign: 'left'
    }
}))
