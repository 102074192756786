import { Divider, Grid, Paper, Skeleton, Tooltip, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { Pie, PieChart, ResponsiveContainer } from "recharts"
import { Empty } from "../../../components"
import { useDashboardContext } from "../../../hooks"
import { useStyles } from './combinationStyles'

const defaultData = {
    secure: { title: 'Secure', fill: '#96D1D7' },
    neutral: { title: 'Neutral', fill: '#BFBFBF' },
    watch: { title: 'Watch', fill: '#D79696' }
}

const legendData = [
    {
        initial: 'Q',
        desc: '[RDQ] Resilience Dynamic® Questionnaire Only'
    },
    {
        initial: 'I',
        desc: '[RDI] Resilience Dynamic® Indicator Only'
    },
    {
        initial: 'B',
        desc: 'Both [RDQ] Questionnaire and [RDI] Indicator'
    }
]

const questionRegex = /(\d\d?)\.\s*(.+)/

const SecureNeutralWatchAreas = () => {
    const classes = useStyles()
    const [data, setData] = useState([])
    const { currentData: { questionAreas, answers }, filters: { type }, isOrgProfile, viewLoading } = useDashboardContext()

    useEffect(() => {
        if (!viewLoading) {
            setData(Object.keys(questionAreas || []).map(key => ({ name: key, value: questionAreas[key].length, ...defaultData[key] })))
        }
    }, [questionAreas, viewLoading])
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <Paper elevation={2} className={classes.paper}>
            <Grid item xs={12} padding={2}>
                <Grid item xs={10} className={classes.header}>
                    <Typography variant='body1' justifyContent='left'><b>Secure, Neutral and Watch Areas</b></Typography>
                </Grid>
                {data.length === 0 && !viewLoading ? <Empty message={!!isOrgProfile ? 'No data to display. This is because not enough people have provided results for the filtered criteria' : 'There is no data to display'} /> :
                    <>
                        <Grid container xs={12} className={classes.grid}>
                            <Grid item xs={2}>
                                {!viewLoading ?
                                    Object.keys(defaultData).map(key => (
                                        <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                                            <div className={classes.chartlegend} style={{ backgroundColor: defaultData[key].fill }} />
                                            <Typography variant='h6'>{defaultData[key].title}</Typography>
                                        </div>)
                                    )
                                    :
                                    <>
                                        <Skeleton variant='rectangular' width='100px' height='30px' style={{ marginBottom: 20 }} />
                                        <Skeleton variant='rectangular' width='100px' height='30px' style={{ marginBottom: 20 }} />
                                        <Skeleton variant='rectangular' width='100px' height='30px' style={{ marginBottom: 20 }} />
                                    </>
                                }
                            </Grid>
                            <Grid item xs={type === 'both' ? 4 : 10}>
                                <ResponsiveContainer width="100%" height={200}>
                                    {!viewLoading ? <PieChart width={400} height={400}>
                                        <Pie data={data}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={data.length ? renderCustomizedLabel : () => { }}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            dataKey="value"
                                            stroke={0}
                                        />
                                        <Tooltip />
                                    </PieChart>
                                        :
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Skeleton variant='circular' width='180px' height='180px' />
                                        </div>
                                    }
                                </ResponsiveContainer>
                            </Grid>
                            {type === 'both' &&
                                <>
                                    <Divider orientation="vertical" flexItem />
                                    <Grid item xs={6} paddingLeft='20px' marginRight='-1px'>
                                        {!viewLoading ?
                                            <>
                                                <Typography variant='h6'><b>Legend</b></Typography>
                                                {legendData.map(({ initial, desc }) => (
                                                    <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                                                        <div className={classes.legend} >{initial}</div>
                                                        <Typography variant='h6'>{desc}</Typography>
                                                    </div>)
                                                )}
                                            </> :
                                            <>
                                                <Skeleton variant='rectangular' width='100%' height='50px' style={{ marginBottom: 20 }} />
                                                <Skeleton variant='rectangular' width='100%' height='50px' style={{ marginBottom: 20 }} />
                                                <Skeleton variant='rectangular' width='100%' height='50px' style={{ marginBottom: 20 }} />
                                            </>
                                        }
                                    </Grid>
                                </>
                            }
                        </Grid>
                        {type === 'both' && <Grid container xs={12} paddingTop='10px'>
                            {!viewLoading ? Object.keys(questionAreas || []).map((key, index) =>
                            (
                                <>
                                    {index > 0 && <Divider orientation="vertical" flexItem />}
                                    <Grid item xs={4} marginRight='-.7px' paddingLeft='10px'>
                                        <Typography display='flex' justifyContent='center'><b>{defaultData[key].title}</b></Typography>
                                        {Array.from(new Set(questionAreas[key]
                                            .map(question => question.match(questionRegex)[2]))
                                        )
                                            .map(text => {
                                                const questions = answers?.filter(({ question: { text: questionText } }) => questionText === text)
                                                const types = []
                                                questions.forEach(({ question: { type } }) => {
                                                    types.push(type)
                                                })
                                                const typesSet = Array.from(new Set(types))
                                                const type = typesSet.length === 2 ? 'both' : typesSet.length === 1 ? typesSet[0] : false
                                                return (
                                                    type && <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                                                        <div className={classes.arealegend}>{type === 'evaluation' ? 'Q' : type === 'indicator' ? 'I' : 'B'}</div>
                                                        <Typography>{text}</Typography>
                                                    </div>
                                                )
                                            }
                                            )}

                                    </Grid>

                                </>
                            )
                            ) :
                                <>
                                    <Skeleton variant='rectangular' width='30%' height='500px' style={{ marginRight: 25 }} />
                                    <Skeleton variant='rectangular' width='30%' height='500px' style={{ marginRight: 25 }} />
                                    <Skeleton variant='rectangular' width='30%' height='500px' style={{ marginRight: 25 }} />
                                </>
                            }
                        </Grid>}
                    </>}
            </Grid>
        </Paper >
    )
}

export default SecureNeutralWatchAreas