import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { DashboardResults, ResilienceIndicatorCard } from '../../../components'
import { getResilienceValues } from '../../../helpers/utils'
import { useDashboardContext } from '../../../hooks'
import { userSlice } from '../../../reducers'
import GenericLoading from '../generic-loading'

const Indicator = () => {
  const dashboardData = useSelector(userSlice.selectors.dashboardData)
  const { changeDashboardFilter } = useDashboardContext()
  const resilienceValues = dashboardData.indicatorReportResult
    ? getResilienceValues(dashboardData.indicatorReportResult)
    : []

  if (changeDashboardFilter.isWorking) return <GenericLoading overrideHeight='240px' />

  return (
    <Grid xs={12} item marginY={1}>
      {resilienceValues.length ? (
        <DashboardResults
          title='Resilience Dynamic® Indicator'
          type='indicator'
          accState
          totalUsers={dashboardData.evaluationTotalUsers}
          results={dashboardData.indicatorReportResult}
          isOrgProfile={false}
          reQuestinnaire={false}
          startHandler={() => null}
        />
      ) : (
        <ResilienceIndicatorCard />
      )}
    </Grid>
  )
}

export default Indicator
