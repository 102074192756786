import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3)
  },
  header: {
    margin: theme.spacing(2, 0, 1, 1)
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  input: {
    marginBottom: theme.spacing(2),
    width: 600,
    backgroundColor: '#F6F8FB',
    borderRadius: 2,
    borderWidth: 1,
    borderColor: '#A0A4A8'
  },
  checkboxContainer: {
    background: '#F6F8FB',
    border: '1px solid #DBDDE0',
    boxSizing: 'border-box',
    borderRadius: '100px',
    padding: theme.spacing(1),
    paddingRight: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    color: '#465270 !important',
    fontWeight: 'bold !important'
  },
  checkboxGroupContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '8px'
  },
  organisationNameCheck: {
    wordWrap: 'break-word',
    maxWidth: 350,
    fontSize: 16,
    fontWeight: 'bold'
  },
  tableCellDef: {
    color: '#25282B',
    fontWeight: 'bold'
  }
}))
