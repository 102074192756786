import React from 'react'
import { IconButton, TextField, Box, Typography } from '@mui/material'
import { useStyles } from './styles'
import { Controller, useFormContext, useFieldArray } from 'react-hook-form'
import { Mood, Https, AddCircle, RemoveCircle } from '@mui/icons-material'

const BasicInfo = () => {
  const classes = useStyles()

  const { control, watch, formState: { errors } } = useFormContext([])
  const { append: addEmail, remove } = useFieldArray({
    control,
    name: 'orgAdminEmail'
  })

  const getInfo = watch()

  const addAdminEmail = (event) => {
    addEmail({
      adminEmail: ''
    })
  }

  const [enable, setEnable] = React.useState(-1)

  return (
    <>
      <Box display='flex' flexDirection='column'>
        <Controller
          name='name'
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <div>
              <TextField
                className={classes.input}
                {...field}
                placeholder='Organisation Name'
                error={!!errors.name}
              />
            </div>
          )}
        />
        <TextField
          className={classes.input}
          placeholder='Unique ID'
          disabled
          InputProps={{
            endAdornment: <Https color='secondary' style={{ opacity: 0.6 }} />
          }}
          value={getInfo.name.replace(/ /g, '-').toLowerCase()}
        />
        {getInfo.orgAdminEmail.map((email, index) => (
          <div key={index} id={index}>
            <Box display='flex' flexDirection='row'>
              <Controller
                name={`orgAdminEmail.${index}.adminEmail`}
                control={control}
                rules={{ required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ }}
                render={({ field }) => (
                  <TextField
                    className={classes.input}
                    disabled={enable !== index}
                    onMouseOver={() => setEnable(index)}
                    {...field}
                    placeholder='Organisation admin e-mail'
                    InputProps={{
                      endAdornment: <Mood color='secondary' style={{ opacity: 0.6 }} />
                    }}
                    error={!!errors.orgAdminEmail}
                  />
                )}
              />
              {getInfo.orgAdminEmail.length > 1 &&
                (
                  <Box mt={1}>
                    <IconButton sx={{ color: '#CCCCCC' }} onClick={() => remove(index)}>
                      <RemoveCircle />
                    </IconButton>
                  </Box>
                )}
            </Box>
            {getInfo.orgAdminEmail.length - 1 === index &&
              (
                <Box ml={-1}>
                  <IconButton color='secondary' onClick={addAdminEmail}>
                    <AddCircle fontSize='small' />
                    <Typography fontSize={14} fontWeight='bold'>Invite another admin</Typography>
                  </IconButton>
                </Box>
              )}
          </div>
        ))}
      </Box>
    </>
  )
}

export default BasicInfo
