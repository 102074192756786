import { Grid, Skeleton, Typography } from '@mui/material'
import { useMemo } from 'react'
import { Empty, ScoreBadge } from '..'
import { resultLabels, resultDescriptions } from '../../helpers/copy'
import useDashboardContext from '../../hooks/useDashboardContext'
import { useStyles } from './styles'
import { roundNumber } from '../../helpers/utils'

export const initialOptions = [
  { key: 'breakdown', name: 'Breakdown', color: '#E56161', position: 0 },
  { key: 'fragmentation', name: 'Fragmentation', color: '#E67756', position: 1 },
  { key: 'fragmentation_coping', name: 'Fragmentation to Coping', color: '#E7894E', position: 2 },
  { key: 'coping', name: 'Coping', color: '#E8A054', position: 3 },
  { key: 'break_even', name: 'Middle of Breakeven', color: '#E8B95C', position: 4 },
  { key: 'bounce_back', name: 'Bounceback', color: '#D8CC6B', position: 5 },
  { key: 'start_whoosh', name: 'Start of Whoosh', color: '#ADD685', position: 6 },
  { key: 'whoosh', name: 'Whoosh', color: '#81E1A0', position: 7 },
  { key: 'breakthrough', name: 'Breakthrough', color: '#4DEFC0', position: 8 }
]

const Scores = ({ loading, empty }) => {
  const { currentData, isOrgProfile } = useDashboardContext()
  const classes = useStyles()

  if (loading) {
    return (
      <Grid width height gap={2} padding={2}>
        <Skeleton variant='rectangular' height='400px' width />
      </Grid>
    )
  }

  if (empty) {
    return null
  }
  return (
    <Grid className={classes.badges}>
      <div className={classes.badge}>
        <ScoreBadge title={resultLabels.orgView.score} description={resultDescriptions.score} vertical center>
          <span style={{ fontWeight: 'bold' }}><b> {currentData && currentData.score ? roundNumber(currentData.score) : 0}</b> out of 6</span>
        </ScoreBadge>
      </div>
      <div className={classes.badge}>
        <ScoreBadge title={resultLabels.orgView.level} description={resultDescriptions.level} vertical={!isOrgProfile} center>
          <b> {currentData && currentData.level} </b>
        </ScoreBadge>
        {isOrgProfile && currentData.mostFrequentResult &&
          <ScoreBadge title={resultLabels.orgView.mostFrequent} description={resultDescriptions.mostFrequent} center>
            <b className={classes.text}> {initialOptions.find(i => i.key === currentData?.mostFrequentResult?.key)?.name || ''} </b>
          </ScoreBadge>}
      </div>
      <div className={classes.badge}>
        <ScoreBadge title={resultLabels.orgView.variability} description={resultDescriptions.variability} vertical center>
          <b className={classes.text}> {currentData && currentData.averageVariance} </b>
        </ScoreBadge>
      </div>
      {isOrgProfile &&
        <div className={classes.badge}>
          <ScoreBadge title={resultLabels.orgView.range} vertical center>
            <b className={classes.text}> {currentData && `${currentData.lowestLevel.level} to ${currentData.highestLevel.level}`} </b>
          </ScoreBadge>
        </div>}
      <div className={classes.badge}>
        <ScoreBadge title={resultLabels.orgView.rangeLow} description={resultDescriptions.range} center vertical>
          <b> {currentData && `${currentData.averageLowScore}`} </b>
        </ScoreBadge>
        <ScoreBadge title={resultLabels.orgView.rangeHigh} description={resultDescriptions.range} center vertical>
          <b> {currentData && `${currentData.averageHighScore}`} </b>
        </ScoreBadge>
      </div>
    </Grid>
  )
}

export const RenderCharts = () => {
  const { viewLoading, currentData, dashboardData, isOrgProfile } = useDashboardContext()
  const classes = useStyles()

  const shouldShowScoresEmpty = useMemo(() => {
    if (!currentData) return true
    return false
  }, [currentData])
  if (!dashboardData?.evaluationTotalUsers && !viewLoading && !dashboardData?.evaluationReportResult) {
    return <Empty message={!!isOrgProfile ? 'No data to display. This is because not enough people have provided results for the filtered criteria' : 'There is no data to display'} />
  }
  return (
    <Grid className={classes.grid}>
      <div className={classes.charts}>
        <Scores loading={viewLoading} empty={shouldShowScoresEmpty} />
        {isOrgProfile && (viewLoading || !dashboardData?.evaluationTotalUsers) ?
          <Grid width height gap={2} padding={2}>
            <Skeleton variant='rectangular' height='400px' width />
          </Grid> :
          <div className={classes.img}>
            {dashboardData?.evaluationReportResult && !viewLoading ?
              <img alt='level' src={`/img/resilience-levels/${dashboardData?.evaluationReportResult?.levelKey}.png`} /> :
              <Skeleton variant='rectangular' height='351px' width='535px' />
            }
          </div>}
      </div>
      {isOrgProfile && !viewLoading && <Grid display='flex' justifyContent='flex-end' paddingRight={1}>
        <Typography>{dashboardData?.evaluationTotalUsers} results for the filtered criteria</Typography>
      </Grid>}
    </Grid >
  )
}
