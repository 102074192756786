const addExternalScriptByBody = (body) => {
  const script = document.createElement('script');

  script.innerHTML = body;

  document.body.appendChild(script);
  return script
}

export const addChatBotScript = () => {
  addExternalScriptByBody(`
    <!-- Start of Wchat code -->
    function initFreshChat() {
    window.fcWidget.init({
      token: "e70dac6e-8d24-4b8a-8fe9-e8b28dbd62b6",
      host: "https://wchat.eu.freshchat.com"
    });
  }
  function initialize(i,t){var e;i.getElementById(t)?initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,e.src="https://wchat.eu.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}function initiateCall(){initialize(document,"Freshdesk Messaging-js-sdk")}window.addEventListener?window.addEventListener("load",initiateCall,!1):window.attachEvent("load",initiateCall,!1);
  initiateCall()
  <!-- End of Wchat code -->
  `)
}