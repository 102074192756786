import { Grid, Paper, Skeleton, Tooltip, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { Bar, BarChart, ResponsiveContainer } from "recharts"
import { useDashboardContext } from "../../../hooks"
import { useStyles } from './combinationStyles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { initialOptions } from "../../../components/questionnaire-indicator-card/charts"
import { Empty } from "../../../components"

const ResilienceLevels = () => {
    const classes = useStyles()
    const [data, setData] = useState([])
    const { currentData: { modelResultSum }, isOrgProfile, viewLoading } = useDashboardContext()

    useEffect(() => {
        if (modelResultSum) {
            setData(initialOptions.map(({ key, color, name }) => (
                { name, quantity: modelResultSum[key] ?? 0, fill: modelResultSum[key] <= 3 ? 'none' : color }
            )))
        } else {
            setData([])
        }
    }, [modelResultSum])
    const usersCount = modelResultSum ? Object.keys(modelResultSum).reduce((sum, key) => modelResultSum[key] + sum, 0) : 0
    return (
        <Paper elevation={2} className={classes.paper} >
            <Grid item xs={12} padding={2}>
                <Grid item xs={10} className={classes.header}>
                    <Typography variant='body1' justifyContent='left'><b>Team Resilience Levels</b></Typography>
                    <Tooltip
                        title='Measures the users per resilience level'
                    ><InfoOutlinedIcon className={classes.popoverIcon} /></Tooltip>
                </Grid>
                {viewLoading ? <Skeleton variant='rectangular' width='100%' height='200px' /> : !data.length ? <Empty message={!!isOrgProfile ? 'No data to display. This is because not enough people have provided results for the filtered criteria' : 'There is no data to display'} /> :
                    <>
                        <ResponsiveContainer width="100%" height={200}>
                            <BarChart data={data} barSize={30}>
                                <Bar dataKey="quantity" />
                            </BarChart>
                        </ResponsiveContainer>
                        <Grid display='flex'>
                            {data.map(item => (<div style={{ width: `${(100 / data.length)}%`, display: 'flex', justifyContent: 'center' }}><span>{item.name.split(' ').map(name => <div style={{ justifyContent: 'center', display: 'flex', fontSize: '12px' }}>{name}</div>)}</span></div >))}
                        </Grid>
                        <Grid display='flex' justifyContent='flex-end' paddingRight={1}>
                            <Typography justifyContent='right' variant='body1'>{usersCount} answers for the filtered criteria</Typography>
                        </Grid>
                    </>
                }
            </Grid>
        </Paper >
    );
}

export default ResilienceLevels