import { Drawer, LinearProgress, Paper, Typography } from '@mui/material'
import React, { createContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { StepZero } from './step-zero'
import { StepOne } from './step-one'
import { useStyles } from './style'
import { Box } from '@mui/system'
import { clientConfigSlice, userSlice } from '../../reducers'
import { AppToolbar } from '../../components'

export const inviteDrawerWidth = 256

const renderName = (name) => {
  if (name.length > 15) {
    return name.substring(0, 15) + '...'
  }
  return name
}

export const InvitationContext = createContext({})
const AdditionalUserSettings = () => {
  const classes = useStyles()
  const user = useSelector(userSlice.selectors.selectUser)
  const clientConfig = useSelector(clientConfigSlice.selectors.selectClientConfig)
  const [step, setStep] = React.useState(0)
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      hierarchyField: null,
    }
  })

  const renderStep = () => {
    switch (step) {
      case 0:
        return <StepZero />
      case 1:
        return <StepOne />
      default:
        return <div>Not found</div>
    }
  }

  const renderSidebarPicture = () => {
    let src = null
    if (user?.profilePic) {
      src = user.profilePic.url
    }
    if (!src) return null
    return <img className={classes.profileImage} src={src} alt='profile' />
  }

  useEffect(() => {
    if (!clientConfig) return null
    const { personalSettings, name } = user
    const { demographicFields, hierarchyField } = clientConfig
    if (personalSettings) {
      methods.setValue('name', name)
      demographicFields.forEach(field => {
        methods.setValue(field.fieldKey, personalSettings[field.fieldKey])
      })
      if (hierarchyField) {
        methods.setValue('hierarchyField', personalSettings.hierarchyField)
      }

    }
  }, [user, methods, clientConfig])

  const watchedFields = methods.watch()
  return (
    <div className={classes.root}>
      <AppToolbar />
      <div className={classes.LeftDrawer}>
        <Drawer
          sx={{
            width: inviteDrawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: inviteDrawerWidth,
              boxSizing: 'border-box'
            }
          }}
          variant='permanent'
          anchor='left'
        >
          <div className={classes.drawerContent}>
            <img
              src='/img/logo-v2.svg'
              alt='Resilience Engine Logo'
              className={classes.image}
            />
          </div>
          <div className={classes.profileWrapper}>
            <div className={classes.profileImageWrapper}>
              {renderSidebarPicture()}
            </div>
            <Typography className={classes.profileName} variant='body1'>
              {watchedFields.name && watchedFields.name.length
                ? renderName(watchedFields.name) : 'Welcome!'}
            </Typography>
          </div>
        </Drawer>
      </div>
      <Box
        component='main'
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 0 }}
      >
        <div className={classes.inviteHeader}>
          <Typography variant='h5' color='secondary'>Resilience Dynamic Dashboard® Organisational</Typography>
        </div>
        <Paper className={classes.content}>
          <div>
            <LinearProgress variant='determinate' value={(100 * step / 2)} />
            <Typography variant='caption'>Step {step + 1} of 2</Typography>
          </div>
          <InvitationContext.Provider value={{ setStep }}>
            <FormProvider {...methods}>
              {renderStep(step)}
            </FormProvider>
          </InvitationContext.Provider>
        </Paper>
      </Box>
    </div>
  )
}

export default AdditionalUserSettings
