import React, { useCallback } from 'react'
import { Button, CircularProgress } from '@mui/material'
import { useStyles } from './style'
import classNames from 'classnames'

const ReButton = ({ name, action, color, bgColor, btBorderWidth, btBorderColor, dataTestId, disabled, tiny, small, medium, rounded, fontSize, loading, icon = null, fullWidth, variant, className, ...rest }) => {
  const styles = useStyles({ small, color, bgColor, btBorderWidth, btBorderColor, fontSize, rounded })
  const getStyles = useCallback(() => {
    const classes = [styles.button]
    if (tiny) classes.push(styles.tiny)
    if (small) classes.push(styles.small)
    if (medium) classes.push(styles.medium)
    classes.push(styles.customizable)
    return classNames(classes)
  }, [tiny, small, medium, styles])

  return (
    <Button
      disabled={disabled || loading}
      onClick={action}
      fullWidth={fullWidth}
      variant={variant || 'outlined'}
      className={classNames(getStyles(), className)}
      data-test={dataTestId}
      startIcon={icon}
      {...rest}
      endIcon={loading ? <CircularProgress size={20} /> : null}
    >
      {name}
    </Button>
  )
}

export default ReButton
