import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Button, ButtonGroup, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import Timeline, { DateHeader, SidebarHeader, TimelineHeaders } from 'react-calendar-timeline'
import { Controller, useFormContext } from 'react-hook-form'
import uuid from 'uuid/v4'
import { useStyles } from './style'
import { CustomOverlay } from '../../../../components'
import { EditCohortContext } from '..'
import { AddBox } from '@mui/icons-material'
import classNames from 'classnames'
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { useSelector } from 'react-redux'
import { cohortSlice, userSlice } from '../../../../reducers'
import { EntryModal } from './entry-modal'
import '../../../../timeline-style.css'

const minTime = moment().add(-1, 'years').valueOf()
const maxTime = moment().add(10, 'years').valueOf()

export default function StepThree () {
  const classes = useStyles()
  const { cohortFeatureEntryWasAdded, cohortFeatureEntryWasRemoved } = useContext(EditCohortContext)
  const { control, getValues, setValue } = useFormContext()
  const features = useSelector(cohortSlice.selectors.selectFeatureList)
  const [visibleTimeStart, setVisibleTimeStart] = useState(moment().startOf('month').toDate())
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(moment().startOf('month').add(3, 'month').toDate())
  const [timeInterval, setTimeInterval] = useState('months')
  const licenseList = useSelector(cohortSlice.selectors.selectLicenseList)
  const { id: tenantKey } = useSelector(userSlice.selectors.selectedView)

  const licenseInterval = useMemo(() => {
    const licenseId = getValues('licenseId')
    const { startDate, endDate } = licenseList.find(license => license.sk === licenseId)
    return { startDate, endDate }
  }, [licenseList, getValues])
  const [cohortFeatureEntryWasEdited] = useCorvusEventList([{ eventName: 'Cohort Feature Entry Was Edited' }])

  const addNewEntry = async (newEntry) => {
    const current = getValues('entries')
    const cohortId = getValues('cohortId')
    const license = getValues('licenseId')
    try {
      const result = await cohortFeatureEntryWasAdded.promise({
        cohortId,
        featureId: features.find(item => item.id === newEntry.group).pk,
        start: newEntry.start_time,
        end: newEntry.end_time,
        license,
        trackerSettings: newEntry.trackerSettings,
        isTrackerMandatory: newEntry.isTrackerMandatory,
        tenantKey
      })
      const newItem = { ...newEntry, entryId: result.sk, id: uuid() }
      setValue('entries', [...current, { ...newItem }])
    } catch (e) {
      console.log(e)
    }
  }

  const editEntry = useCallback(async (newEntry) => {
    const { entryId, group, start_time, end_time, id, trackerSettings, isTrackerMandatory } = newEntry
    const current = getValues('entries')
    const newEntries = current.map(item => {
      if (item.id === id) {
        const newItem = {
          ...item,
          start_time: moment(start_time),
          end_time: moment(end_time),
          trackerSettings,
          isTrackerMandatory
        }
        return newItem
      }
      return item
    })
    setValue('entries', newEntries)
    const cohortId = getValues('cohortId')
    const license = getValues('licenseId')
    const payload = {
      cohortId,
      entryId: entryId,
      featureId: features.find(feat => feat.id === group).pk,
      start: moment(start_time),
      end: moment(end_time),
      license,
      trackerSettings,
      isTrackerMandatory,
      tenantKey
    }
    cohortFeatureEntryWasEdited.trigger(payload)
  }, [cohortFeatureEntryWasEdited, features, getValues, setValue, tenantKey])

  const onDelete = useCallback(async (entry) => {
    const current = getValues('entries')
    const cohortId = getValues('cohortId')
    setValue('entries', current.filter(item => item.id !== entry.id))
    await cohortFeatureEntryWasRemoved.promise({ entryId: entry.entryId, cohortId, tenantKey })
  }, [getValues, setValue, cohortFeatureEntryWasRemoved, tenantKey])

  const changeTimeInterval = (interval) => {
    setTimeInterval(interval)
    const middle = visibleTimeStart + ((visibleTimeEnd - visibleTimeStart) / 2)
    if (interval === 'weeks') {
      setVisibleTimeStart(moment(middle).add(-2, 'weeks'))
      setVisibleTimeEnd(moment(middle).add(2, 'weeks'))
    }
    if (interval === 'months') {
      setVisibleTimeStart(moment(middle).add(-2, 'months'))
      setVisibleTimeEnd(moment(middle).add(2, 'months'))
    }
    if (interval === 'quarters') {
      setVisibleTimeStart(moment(middle).add(-2, 'quarters'))
      setVisibleTimeEnd(moment(middle).add(2, 'quarters'))
    }
  }

  const checkIfEntryIsInRange = (startTime, endTime) => {
    const { startDate, endDate } = licenseInterval
    if (startTime && moment(startTime).isBefore(startDate)) {
      alert('Start date is before the license start date')
      return false
    }
    if (endTime && moment(endTime).isAfter(endDate)) {
      alert('End date is after the license end date')
      return false
    }
    return true
  }

  return (
    <div className={classes.root}>
      <div className={classes.headerWrapper}>
        <Typography variant='h4' className={classes.header}>Features Available</Typography>
        <ButtonGroup variant='outlined' color='secondary' aria-label='outlined button group'>
          <Button data-test='cohort-feature-interval-weeks' onClick={() => changeTimeInterval('weeks')} variant={timeInterval === 'weeks' ? 'contained' : 'outlined'}>Weeks</Button>
          <Button data-test='cohort-feature-interval-months' onClick={() => changeTimeInterval('months')} variant={timeInterval === 'months' ? 'contained' : 'outlined'}>Months</Button>
          <Button data-test='cohort-feature-interval-quarters' onClick={() => changeTimeInterval('quarters')} variant={timeInterval === 'quarters' ? 'contained' : 'outlined'}>Quarters</Button>
        </ButtonGroup>
      </div>
      <div>
        <Typography component='strong' variant='caption' style={{ fontWeight: 'bold' }}>Hint:</Typography>
        <Typography component='span' variant='caption'> Double click on an entry to edit it</Typography>
      </div>
      {features.length > 0 &&
        (
          <CustomOverlay
            active={cohortFeatureEntryWasAdded.event.isWorking || cohortFeatureEntryWasEdited.isWorking || cohortFeatureEntryWasRemoved.event.isWorking}
          >
            <Controller
              name='entries'
              control={control}
              render={({ field }) =>
                <Timeline
                  groups={features}
                  items={field.value}
                  onItemMove={(itemId, dragTime) => {
                    const item = field.value.find(item => item.id === itemId)
                    const start_time = dragTime
                    const end_time = dragTime + item.end_time - item.start_time
                    if (checkIfEntryIsInRange(start_time, end_time)) {
                      editEntry({ ...item, start_time, end_time })
                    }
                  }}
                  onItemResize={(itemId, time, edge) => {
                    if (edge === 'right') {
                      const item = field.value.find(item => item.id === itemId)
                      if (checkIfEntryIsInRange(null, time)) {
                        editEntry({ ...item, end_time: moment(time) })
                      }
                    }
                  }}
                  canChangeGroup={false}
                  lineHeight={54}
                  sidebarWidth={310}
                  visibleTimeStart={visibleTimeStart}
                  visibleTimeEnd={visibleTimeEnd}
                  canResize='right'
                  onTimeChange={(visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
                    const update = (start, end) => {
                      setVisibleTimeStart(start)
                      setVisibleTimeEnd(end)
                      updateScrollCanvas(start, end)
                    }
                    if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
                      update(minTime, maxTime)
                    } else if (visibleTimeStart < minTime) {
                      update(minTime, minTime + (visibleTimeEnd - visibleTimeStart))
                    } else if (visibleTimeEnd > maxTime) {
                      update(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime)
                    } else {
                      update(visibleTimeStart, visibleTimeEnd)
                    }
                  }}
                  itemRenderer={(props) =>
                    <ItemRenderer
                      minDate={licenseInterval.startDate}
                      maxDate={licenseInterval.endDate}
                      editEntry={editEntry} onDelete={onDelete} {...props}
                      group={features.find(feat => feat.id === props.item.group)}
                    />}
                  groupRenderer={({ group }) =>
                    <GroupRenderer
                      group={group}
                      minDate={licenseInterval.startDate}
                      maxDate={licenseInterval.endDate}
                      addNewEntry={addNewEntry}
                    />}
                >
                  <TimelineHeaders>
                    <SidebarHeader>
                      {({ getRootProps }) => {
                        return (
                          <div className={classes.sidebarHeader} {...getRootProps()}>
                            <Typography className={classes.sidebarHeaderText}>Feature</Typography>
                          </div>
                        )
                      }}
                    </SidebarHeader>
                    <DateHeader unit='primaryHeader' />
                    <DateHeader />
                  </TimelineHeaders>
                </Timeline>}
            />
          </CustomOverlay>)}
    </div>
  )
}

const ItemRenderer = ({
  item,
  group,
  itemContext,
  getItemProps,
  getResizeProps,
  editEntry,
  onDelete,
  minDate,
  maxDate
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
  const { useResizeHandle, dimensions, selected } = itemContext
  return (
    <div {...getItemProps(item.itemProps)} onDoubleClick={() => handleOpen()} className={classNames(classes.timelineItem, selected && classes.timelineItemSelected)}>
      <EntryModal
        open={open}
        isEdit
        minDate={minDate}
        maxDate={maxDate}
        isTracker={group.pk === 'RESILIENCE_TRACKER'}
        currentStart={item.start_time}
        currentEnd={item.end_time}
        currentTrackerSettings={item.trackerSettings}
        currentIsTrackerMandatory={item.isTrackerMandatory}
        handleClose={handleClose}
        onSubmit={(startTime, endTime, trackerSettings, isTrackerMandatory) => {
          editEntry({ ...item, start_time: startTime, end_time: endTime, trackerSettings, isTrackerMandatory })
        }}
        onDelete={() => onDelete(item)}
      />
      {useResizeHandle ? <div {...leftResizeProps} /> : ''}
      <div className='rct-item-content' style={{ maxHeight: `${dimensions.height}` }} />
      {useResizeHandle ? <div {...rightResizeProps} /> : ''}
    </div>
  )
}

const GroupRenderer = ({ group, addNewEntry, minDate, maxDate }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const minMomentDate = moment(minDate)
  const maxMomentDate = moment(maxDate)
  const startDate = moment().isBetween(minMomentDate, maxMomentDate) ? moment() : maxMomentDate
  const endDate = moment().add(1, 'month').isBetween(minMomentDate, maxMomentDate) ? moment().add(1, 'month') : maxMomentDate
  return (
    <div className={classes.groupRenderWrapper}>
      <EntryModal
        open={open}
        minDate={minDate}
        maxDate={maxDate}
        currentStart={startDate}
        currentEnd={endDate}
        isTracker={group.pk === 'RESILIENCE_TRACKER'}
        handleClose={handleClose}
        onSubmit={(startDate, endDate, trackerSettings, isTrackerMandatory) => addNewEntry({
          group: group.id,
          start_time: startDate,
          end_time: endDate,
          trackerSettings,
          isTrackerMandatory
        })}
      />
      <IconButton data-test={`add-entry-${group.pk}`} onClick={handleOpen}><AddBox color='secondary' /></IconButton>
      <span className={classes.groupRendererTitle}>{group.title}</span>
      <p className='tip'>{group.tip}</p>
    </div>
  )
}
