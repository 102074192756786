import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    display: 'flex',
    width: '100%',
    backgroundColor: '#FFFFFF'
  },
  paper: {
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)',
    borderRadius: '8px',
    padding: `0 ${theme.spacing(3)}`
  },
  tableHeader: {
    backgroundColor: '#FFFFFF'
  },
  reportsTableHeader: {
    fontWeight: 'bold',
    fontSize: '16px',
    color: theme.palette.secondary.main
  },
  reportsTableBody: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#E5E5E5',
    }
  },
  header: {
    backgroundColor: 'rgba(232, 232, 232, 1)'
  },
  title: {
    padding: `${theme.spacing(3)} 0`,
    borderBottom: '1px solid #E8E8E8'
  },
  subtitle: {
    fontSize: '13px',
    fontWeight: 'bold',
    padding: theme.spacing(0, 0, 1, 0)
  },
  result: {
    fontSize: '13px',
    color: '#52575C',
    fontWeight: 'normal'
  },
  resultBtn: {
    fontSize: '13px',
    color: '#52575C',
    fontWeight: 'normal'
  },
  btnNotes: {
    padding: `${theme.spacing(3)} 0`,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
}))
