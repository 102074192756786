import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { createContext, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { aggregationConfigs, clientSlice, userSlice } from '../reducers'
import useAlert from './useAlert'

const DashboardContext = createContext({})

const useDashboardContext = () => {
  return useContext(DashboardContext)
}

const DashboardContextProvider = ({ children }) => {
  const dispatch = useDispatch()
  const { showErrorAlert } = useAlert()
  const dashboardData = useSelector(userSlice.selectors.dashboardData)
  const selectedView = useSelector(userSlice.selectors.selectedView)
  const filters = useSelector(aggregationConfigs.selectors.selectFilters)
  const dateAsArray = useMemo(() => [filters.date.start, filters.date.end], [filters.date])
  const [getClient] = useCorvusEventList([clientSlice.eventBus.getClientInfo])
  const [changeDashboardFilter] = useCorvusEventList([userSlice.eventBus.changeDashboardFilter])
  const isOrgProfile = useMemo(() => selectedView && selectedView.type === 'org', [selectedView])
  const isIndividualView = useMemo(() => (selectedView && selectedView.type === 'individual') || !selectedView, [selectedView])
  const viewLoading = useSelector(userSlice.selectors.viewLoading)

  const currentData = useMemo(() => filters?.type === 'indicator' ? dashboardData?.indicatorReportResult : dashboardData?.evaluationReportResult, [dashboardData, filters?.type])

  useEventsOnViewLoad(() => {
    changeDashboardFilter.registerAdhocOnErrorListener((err) => showErrorAlert(err?.message || 'An error has occurred.' ))
  }, [changeDashboardFilter])

  useEffect(() => {
    if (!filters?.type.length && selectedView?.type === 'org') return
    if (selectedView !== null) {
      dispatch(userSlice.actions.setViewLoading(true))
      console.log('triggering on context')
      changeDashboardFilter.trigger({ ...filters, view: selectedView })
    }
  }, [selectedView, changeDashboardFilter, filters, dispatch])

  useEventsOnViewLoad(() => {
    if (selectedView?.type === 'org' && getClient) {
      getClient.trigger({ clientId: selectedView.id })
    }
  }, [getClient, selectedView])

  return (
    <DashboardContext.Provider
      value={{
        getClient,
        changeDashboardFilter,
        selectedView,
        viewLoading,
        filters,
        dateAsArray,
        isOrgProfile,
        isIndividualView,
        dashboardData,
        currentData
      }}
    >
      {children}
    </DashboardContext.Provider>)
}

export { DashboardContextProvider }
export default useDashboardContext
