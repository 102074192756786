import { useState, useEffect, useCallback, useMemo } from 'react'
import { Button, Typography, Dialog, Skeleton, MenuItem, TextField } from '@mui/material'
import { indicatorSlice, userSlice } from '../../reducers'
import { useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { SurveyTimeline, ReButton, Loading, ModalQuestionnaire, Survey } from '../../components'
import { useNavigate } from 'react-router-dom'
import useResolvePathFromBaseURL from '../../helpers/useResolvePathFromBaseURL'
import { useSelector, useDispatch } from 'react-redux'
import { useStyles } from './style'
import { loadIndicatorsSteps, calculateIndicator, loadIndicatorsAnswers } from '../../reducers/indicator/slice'
import { validateEmail } from '../../helpers/utils'
import clientConfig from '../../reducers/clientConfig'
import API from '../../services/api'
import { addChatBotScript } from '../../utils/addExternalScript'

const Indicator = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatcher = useDispatch()
  const [email, setEmail] = useState('')
  const [tempValues, setTempValues] = useState({})
  const [step, setStep] = useState(0)
  const [stepFinished, setStepFinished] = useState(true)
  const indicatorSteps = useSelector(indicatorSlice.selectors.selectSteps)
  const [title, setTitle] = useState(indicatorSteps?.[step]?.title || '')
  const [subtitle, setSubtitle] = useState(indicatorSteps?.[step]?.subtitle || '')
  const [description, setDescription] = useState(indicatorSteps?.[step]?.description || '')
  const [questions, setQuestions] = useState(indicatorSteps?.[step]?.questions || [])
  const client = useSelector(clientConfig.selectors.selectClientConfig)
  const [loadingClientInfo, setLoadingClientInfo] = useState(true)
  const user = useSelector(userSlice.selectors.selectUser)
  const responses = useSelector(indicatorSlice.selectors.selectResponses)
  const isIntroModalOpen = useSelector(indicatorSlice.selectors.selectShowIntroModal)
  const loadingUserIndicatorAnwers = useSelector(indicatorSlice.selectors.selectLoadingUserIndicatorAnwers)
  const clientInfo = useSelector(clientConfig.selectors.selectClientConfig)
  const dispatch = useDispatch()


  const setUserEmail = (value) => {
    setEmail(value.toLowerCase())
  }

  const isInvalidCustomForm = (() => {
    if (loadingClientInfo) return true
    for (const key of Object.keys(tempValues)) {
      const required = clientInfo?.demographicFields?.find(({ fieldKey }) => fieldKey === key)?.required || key === 'hierarchyField'
      if (((!tempValues[key] || tempValues[key]?.length <= 0) && required) || !tempValues) {
        return true
      }
    }
    return false
  })()

  const { pathname: dashboardPath } = useResolvePathFromBaseURL('dashboard')



  useEventsOnViewLoad(() => {
    dispatcher(loadIndicatorsSteps())
  }, [loadIndicatorsSteps])


  const setShowIntro = value => {
    dispatcher(indicatorSlice.actions.setShowIntroModal(value))
  }

  useEffect(() => {
    if (indicatorSteps && indicatorSteps?.length) {
      setTitle(indicatorSteps[step].title)
      setSubtitle(indicatorSteps[step].subtitle)
      setDescription(indicatorSteps[step].description)
      setQuestions(indicatorSteps[step].questions)
    }
  }, [indicatorSteps, step])

  useEffect(() => {
    if (!isIntroModalOpen && validateEmail(email)) {
      dispatcher(loadIndicatorsAnswers(email))
    }
  }, [isIntroModalOpen, email, dispatcher])

  useEffect(() => {
    if (questions && responses) {
      const finished = questions?.map(question => {
        const stepResponses = responses?.filter(response => response.question.step === question.step)
        return stepResponses.findIndex(response => response.question.position === question.position)
      }).indexOf(-1) < 0
      setStepFinished(finished)
    }
  }, [questions, stepFinished, responses])


  useEffect(() => {
    if (client) return null
    async function fetchClientInfo() {
      setLoadingClientInfo(true)
      const { data } = await new API().get('client-info', { clientId: 'unauthenticated' })
      if (!data) {
        console.error('Error fetching client info')
        return
      }
      const fields = { hierarchyField: null }
      data.demographicFields.forEach(field => fields[field.fieldKey] = null)
      setTempValues(fields)
      dispatch(clientConfig.actions.setClientConfig(data))
      if (data.chatBot) addChatBotScript()
      setLoadingClientInfo(false)
    }
    fetchClientInfo()
  }, [dispatch, client])

  const handleReturn = () => {
    navigate(dashboardPath)
  }

  const goTo = (action = 'previous', calculate = false) => {
    const newStep = action === 'next' ? step + 1 : step - 1
    setStep(newStep)
    if (calculate) {
      dispatcher(calculateIndicator(email))
    }
    window.scrollTo(0, 90)
  }

  const subsmitAuthenticatedUser = async () => {
    await new API().post('new-user', { email, personalSettings: tempValues })
  }

  return (
    <div className={classes.root}>
      <main className={classes.main}>
        {isIntroModalOpen && (
          <Dialog
            open={isIntroModalOpen}
            classes={{ paperWidthSm: classes.modal }}
            spacing={3}
          >
            <ModalQuestionnaire
              title='Resilience Dynamic® Indicator'
              subtitle={'Welcome to the Resilience Engine\'s leading resilience and wellbeing evaluation tool.'}
              text={'Explore your own resilience: discover the dynamic nature of your own resilience level, how it varies between different contexts, and consider how to put into practice the Resilience Engine\'s recommendations. Click on the button below to begin your Resilience Dynamic® Indicator. '}
              nextLabel='Begin'
              nextAction={() => { subsmitAuthenticatedUser(); setShowIntro(false) }}
              enableSkipAction={false}
              setEmail={setUserEmail}
              email={email}
              skipAction={handleReturn}
              customForm={<CustomForm tempValues={tempValues} setTempValues={setTempValues} clientInfo={clientInfo} />}
              customValidation={isInvalidCustomForm}
            />
          </Dialog>
        )}
        {!indicatorSteps?.length ? (<Loading />) : (
          <span>
            <Typography className={classes.title} variant='h4' color='secondary'>{title}</Typography>
            <Typography className={classes.description}>{subtitle}</Typography>
            <SurveyTimeline steps={indicatorSteps} step={step} />
            {indicatorSteps && indicatorSteps[step]?.id !== 'complete' && (
              <div dangerouslySetInnerHTML={{ __html: description }} />
            )}
            {questions && questions.map(question => {
              const answers = responses
              const answer = answers
                ? answers.find(ans => ans.question.type === question.type &&
                  ans.question.step === question.step &&
                  ans.question.position === question.position &&
                  parseInt(ans.cycle) === parseInt(user?.currentIndicatorCycle || 1))
                : undefined

              return (
                <Survey key={question.id} question={question} answer={answer} email={email} />
              )
            })}
            {calculateIndicator.isWorking && (<Loading message='Calculating Results...' />)}
            {!calculateIndicator.isWorking && indicatorSteps[step].id !== 'complete' && (
              <div className={classes.footer}>
                {indicatorSteps && indicatorSteps[step].id !== 'initial' && (
                  <Button className={classes.previousButton} color='secondary' variant='outlined' data-test='previous-indicator-btn' onClick={() => goTo('previous')}>
                    Previous section
                  </Button>
                )}
                {step < (indicatorSteps?.length - 2)
                  ? (<ReButton className={classes.footerButton} name='Next Section' dataTestId='next-indicator-btn' disabled={(!stepFinished && step > 0) || loadingUserIndicatorAnwers || isIntroModalOpen} loading={loadingUserIndicatorAnwers} action={() => goTo('next')} />)
                  : (<ReButton className={classes.footerButton} name='Calculate score' dataTestId='calc-score-btn' disabled={!stepFinished} action={() => goTo('next', true)} />)}
              </div>
            )}
            {!calculateIndicator.isWorking && indicatorSteps && indicatorSteps[step].id === 'complete' && (
              <div className={classes.resultContainer}>
                <Typography className={classes.resultHeader} variant='body2'>Your score and resilience level was sent to the {email} email inbox.</Typography>
              </div>
            )}
          </span>
        )}
      </main>
    </div>
  )
}

const CustomForm = ({ tempValues, setTempValues, clientInfo }) => {
  const classes = useStyles()
  const isLoading = (() => {
    const events = [
      clientInfo
    ]
    for (const event of events) {
      if (event?.isWorking || !event) return true
    }
    return false
  })()

  const getFields = useCallback((type) => {
    if (!clientInfo) return null
    return clientInfo.demographicFields?.filter(f => f.fieldCategory === type)
      .map(f => {
        return <RenderInput field={f} tempValues={tempValues} setTempValues={setTempValues} />
      })
  }, [clientInfo, tempValues, setTempValues])
  const personalFields = useMemo(() => getFields('personal'), [getFields])
  const occupationFields = useMemo(() => getFields('occupation'), [getFields])


  if (isLoading) {
    return <Loading />
  }
  return <div className={isLoading ? '' : classes.fieldsContainer}>
    {occupationFields?.length > 0 && occupationFields}
    {personalFields?.length > 0 && personalFields}
    {isLoading ? <Skeleton style={{ marginBottom: '-20px' }} variant='text' animation='wave' height='80px' width='100%' /> :

      clientInfo?.hierarchyField && <TextField
        className={classes.field}
        label={clientInfo?.hierarchyField?.fieldName}
        select
        required
        value={tempValues?.hierarchyField}
        onChange={e => { setTempValues(prev => ({ ...prev, hierarchyField: e.target.value })) }}
      >
        < MenuItem value=''>None</MenuItem>
        {clientInfo?.hierarchyField?.fieldOptions?.map(option =>
          <MenuItem value={option.key} key={option.key}>{option.name}</MenuItem>
        )}

      </TextField>
    }
  </div>

}

const RenderInput = ({ field, setTempValues, tempValues }) => {
  const { fieldType, fieldKey, fieldOptions, fieldName, required } = field
  const isSelectField = fieldType === 'select'
  const classes = useStyles()
  return (
    <TextField
      className={classes.field}
      select={isSelectField}
      value={tempValues[fieldKey]}
      variant='outlined'
      label={fieldName}
      required={required}
      defaultValue=''
      onChange={e => { setTempValues(prev => ({ ...prev, [fieldKey]: e.target.value })) }}
    >
      {isSelectField &&
        < MenuItem value=''>None</MenuItem>}
      {isSelectField &&
        fieldOptions.map((value, index) => (
          <MenuItem key={index} value={value.key}>{value.name}</MenuItem>
        ))
      }
    </TextField >
  )
}



export default Indicator
