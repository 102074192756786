import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { FormControl, MenuItem, TextField, Typography, Button } from '@mui/material'
import React, { useContext, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useFormContext } from 'react-hook-form'
import decamelize from 'decamelize'
import { InvitationContext } from '..'
import { ReButton, AvatarUploader } from '../../../components'
import { useStyles } from './style'
import { useEventContext } from '../../../contexts/event'
import { usePromiseEvent } from '../../../hooks'
import { sendS3File } from '../../../helpers/utils'
import useNavigate from '../../../helpers/useNavigation'
import { clientConfigSlice, userSlice } from '../../../reducers'

const nameField = { fieldCategory: "personal", fieldKey: "name", fieldName: "Full name", fieldType: "input" }

const StepOne = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { control, getValues, formState: { errors }, trigger } = useFormContext()
  const clientConfig = useSelector(clientConfigSlice.selectors.selectClientConfig)
  const events = useEventContext()
  const context = useContext(InvitationContext)
  const requestPreSignedUrl = usePromiseEvent('Profile Picture PreSigned Url Upload Was Requested')
  const user = useSelector(userSlice.selectors.selectUser)
  const [localPicture, setLocalPicture] = React.useState(user?.profilePic?.url ? { value: user?.profilePic?.url } : null)
  const navigate = useNavigate()
  const [sendPersonalSettings, getUserProfilePic] = useCorvusEventList([{
    eventName: 'Personal Settings Was Sent',
    onSuccess: () => {
      navigate('dashboard', {
        state: {
          startOnBoarding: true
        },
        replace: true
      })
    }
  }, userSlice.eventBus.getUserProfilePic])
  useEventsOnViewLoad(() => {
    sendPersonalSettings.registerAdhocOnSuccessListener(({ payload }) => {
      dispatch(userSlice.actions.setPersonalSettings(payload))
    })
  }, [sendPersonalSettings, dispatch])

  const onNextClick = async () => {
    await trigger()
    if (Object.keys(errors).length) {
      const [missingField, info] = Object.entries(errors)[0]
      if (info.message.length) alert(info.message)
      else alert(`The field ${decamelize(missingField).replace(/_/g, ' ')} is required`)
      return
    }
    const values = getValues()
    sendPersonalSettings.trigger(values)
  }
  const onPreviousClick = () => {
    context.setStep(0)
  }
  const handleFileUpload = (e) => {
    if (!e.target || !e.target.files || !e.target.files.length) return
    const file = e.target.files[0]
    const fileReader = new FileReader()
    e.target.value = ''
    fileReader.readAsDataURL(file)
    fileReader.onload = async (e) => {
      setLocalPicture({
        value: e.target.result,
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
        fileContents: file
      })
      const result = await requestPreSignedUrl.promise({ type: file.type })
      await sendS3File(result.url, file, file.type)
      getUserProfilePic.trigger()
    }
  }

  const getFields = useCallback((type) => {
    if (!clientConfig) return null
    return clientConfig.demographicFields
      .filter(f => f.fieldCategory === type)
      .map(f => <RenderInput field={f} control={control} errors={errors} />)
  }, [clientConfig, control, errors])
  const personalFields = useMemo(() => getFields('personal'), [getFields])
  const occupationFields = useMemo(() => getFields('occupation'), [getFields])

  if (events.getUser.isWorking) {
    return <Typography>Loading your information...</Typography>
  }
  return (
    <div>
      <Typography variant='h3' className={classes.stepsHeader}>Resilience Dynamic Dashboard® Profile Settings</Typography>
      <Typography variant='h5' className={classes.title}>How is my data being used?</Typography>
      <p>The data collected through the use of the Resilience Dynamic Dashboard® is used anonymously by the Resilience Engine for research purposes.</p>

      <p>Your organisation has requested that the following information is captured. The purpose of this is to track and aggregate data and activity on the dashboard, then filter by the fields below.</p>

      <p><b>Note:</b> Managers and leaders have access to a Team Dashboard which displays an aggregation of their team's data, offering insight into the average resilience and wellbeing of their people as a whole. Managers cannot view individual results and can only see averages for 4 or more participants at a time.</p>
      <div className={classes.formWrapper}>
        <AvatarUploader localPicture={localPicture} setLocalPicture={setLocalPicture} handleFileUpload={handleFileUpload} />
        <div className={classes.fieldsWrapper}>
          <div className={classes.fieldsContainer}>
            <Typography variant='h6'>Personal Information</Typography>
            <RenderInput field={nameField} control={control} errors={errors} />
            {personalFields}
          </div>

          {occupationFields.length > 0 && <div className={classes.fieldsContainer}>
            <Typography variant='h6'>Occupation Information</Typography>
            {occupationFields}
          </div>}
        </div>
      </div>
      <img src='https://static.resilienceengine.live/img/additional-user-settings-step-two-dynamic.png' alt='Additional User Settings Step Two' />
      <div className={classes.buttons}>
        <Button dataTestId='Welcome-settings-button-previous' size='large' color='secondary' variant='outlined' onClick={onPreviousClick} className={classes.previousButton} name='Previous'>Previous</Button>
        <ReButton dataTestId='Welcome-settings-button-start' size='large' disabled={sendPersonalSettings.isWorking} action={onNextClick} className={classes.formButton} name='Continue to Dashboard' />
      </div>
    </div>
  )
}

const RenderInput = ({ field, control, errors }) => {
  const { fieldType, fieldName, fieldKey, fieldOptions } = field
  switch (fieldType) {
    case 'input': {
      return (
        <Controller
          name={fieldKey} control={control} rules={{ required: true }}
          render={({ field }) =>
            <TextField
              inputProps={{ 'data-test': `Welcome-settings-input-${fieldKey}` }}
              {...field}
              variant='outlined'
              label={fieldName}
              required
              error={!!errors[fieldKey]}
            />}
        />
      )
    }
    case 'select': {
      return (
        <Controller
          name={fieldKey}
          control={control}
          rules={{ required: true }}
          render={({ field }) =>
            <FormControl>
              <TextField
                {...field}
                inputProps={{ 'data-test': `Welcome-settings-input-${fieldKey}` }}
                variant='outlined' label={fieldName}
                error={!!errors[fieldKey]}
                select
                required
              >
                <MenuItem value=''>None</MenuItem>
                {
                  fieldOptions.map((value, index) => (
                    <MenuItem key={index} value={value.key}>{value.name}</MenuItem>
                  ))
                }
              </TextField>
            </FormControl>}
        />
      )
    }
    default:
      return null
  }
}

export { StepOne }
