import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { EventBusProvider } from '@emerald-works/react-event-bus-client'
import { AuthProvider } from '@emerald-works/react-auth'
import { Hub } from 'aws-amplify'

import { CustomThemeProvider } from './theme'
import { authSettings } from '../libs/amplify'
import { ReLoading, ReLoadingAuth, ReAuthenticatingAuth } from '../components'
import API from './../services/api'
import { tryParseJson } from '../helpers/utils'
import { AlertContextProvider } from './alert'
import clientConfig from '../reducers/clientConfig'
import { addChatBotScript } from '../utils/addExternalScript'

const RootContextProvider = ({ children }) => {
  const params = useParams()
  const navigate = useNavigate()
  const [loadingClientInfo, setLoadingClientInfo] = useState(true)
  const client = useSelector(clientConfig.selectors.selectClientConfig)
  const dispatch = useDispatch()
  const location = useLocation()

  const eventBusAuthTokenConfig = {
    generate: true,
    apiGatewayAddress: process.env.REACT_APP_API_GATEWAY_ADDRESS,
    awsRegion: process.env.REACT_APP_API_GATEWAY_REGION,
    generateTokenEndpoint: '/generate-token'
  }

  const handleAuthRedirection = ({ Auth, federatedSignInParams }) => {
    const { pathname } = location
    const [, , ...path] = pathname.split('/')
    // prevent redirecting back to the same page
    if (path[0] === 'invite') {
      navigate('signin')
    } else {
      navigate('signin', { state: { redirectTo: path.join('/') } })
    }
  }

  const amplifyAuthConfig = useMemo(() => {
    if (!client) return null
    const baseAuthSettings = { ...authSettings(params), ...client.cognitoSettings }
    baseAuthSettings.oauth.domain = client.customDomain || `${client.cognitoSettings.userPoolDomain}.auth.${client.cognitoSettings.region}.amazoncognito.com`
    return baseAuthSettings
  }, [client, params])

  useEffect(() => {
    if (client) return null
    async function fetchClientInfo() {
      if (!params.tenantKey) {
        console.error('Tenant key is required')
        return
      }
      const { data } = await new API().get('client-info', { clientId: params.tenantKey })
      if (!data) {
        console.error('Error fetching client info')
        return
      }
      dispatch(clientConfig.actions.setClientConfig(data))
      if (data.chatBot) addChatBotScript()
      setLoadingClientInfo(false)
    }
    fetchClientInfo()
  }, [params, dispatch, client])

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event === 'customOAuthState') {
        const parse = tryParseJson(data)
        if (parse.type === 'from-sign-in') {
          navigate(parse.redirectTo)
        }
      }
    })
  }, [navigate])

  if (loadingClientInfo || !amplifyAuthConfig) {
    return null
  }

  return (
    <CustomThemeProvider>
      <AuthProvider
        amplifyAuthConfig={amplifyAuthConfig}
        onAuthNeededRedirection={handleAuthRedirection}
        allowGuestUsers
        signOutPath='/signout'
        eventBusAuthTokenConfig={eventBusAuthTokenConfig}
        LoadingAuthComponent={<ReLoadingAuth />}
        AuthenticatingAuthComponent={<ReAuthenticatingAuth />}
      >
        <EventBusProvider
          eventBusURL={process.env.REACT_APP_EVENT_BUS_URL}
          connectionParams={{
            tenantKey: params.tenantKey || 'devsquad'
          }}
          useAuthProvider
          namespace='app-name'
          waitForConnection
          LoadingComponent={<ReLoading />}
        >
          <AlertContextProvider>
            {children}
          </AlertContextProvider>
        </EventBusProvider>
      </AuthProvider>
    </CustomThemeProvider>
  )
}

export default RootContextProvider
