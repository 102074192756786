import React from 'react'
import { Button, Typography, Link, Dialog } from '@mui/material'
import { indicatorSlice, userSlice } from '../../reducers'
import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { SurveyTimeline, SurveyQuestion, IndicatorSurveyResult, ReButton, Loading, ModalQuestionnaire } from '../../components'
import { useLocation, useNavigate } from 'react-router-dom'
import useResolvePathFromBaseURL from '../../helpers/useResolvePathFromBaseURL'
import { useSelector, useDispatch } from 'react-redux'
import { useStyles } from './style'
import { useOnboarding } from '../../hooks'
import { featureSelector } from '../../reducers/user/selectors'
import { Forbidden } from '../../pages'
import { useEventContext } from '../../contexts/event'

const ReIndicator = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatcher = useDispatch()
  const location = useLocation()
  const { next } = useOnboarding()

  const { pathname: dashboardPath } = useResolvePathFromBaseURL('dashboard')

  const [getIndicatorsSteps] = useCorvusEventList([indicatorSlice.eventBus.getSteps])
  const [calculateIndicator] = useCorvusEventList([indicatorSlice.eventBus.calculate])
  const indicatorSteps = useSelector(indicatorSlice.selectors.selectSteps)
  const user = useSelector(userSlice.selectors.selectUser)
  const responses = useSelector(indicatorSlice.selectors.selectResponses)
  const result = useSelector(indicatorSlice.selectors.selectResult)
  const isIntroModalOpen = useSelector(indicatorSlice.selectors.selectShowIntroModal)
  const features = useSelector(featureSelector)
  const { getUser } = useEventContext()

  useEventsOnViewLoad(() => {
    getIndicatorsSteps.trigger()
  }, [getIndicatorsSteps])

  const [step, setStep] = React.useState(0)
  const [stepFinished, setStepFinished] = React.useState(true)
  const [title, setTitle] = React.useState(indicatorSteps?.[step]?.title || '')
  const [subtitle, setSubtitle] = React.useState(indicatorSteps?.[step]?.subtitle || '')
  const [description, setDescription] = React.useState(indicatorSteps?.[step]?.description || '')
  const [questions, setQuestions] = React.useState(indicatorSteps?.[step]?.questions || [])

  const setShowIntro = value => {
    dispatcher(indicatorSlice.actions.setShowIntroModal(value))
  }

  React.useEffect(() => {
    if (indicatorSteps && indicatorSteps.length) {
      setTitle(indicatorSteps[step].title)
      setSubtitle(indicatorSteps[step].subtitle)
      setDescription(indicatorSteps[step].description)
      setQuestions(indicatorSteps[step].questions)
    }
  }, [indicatorSteps, step])

  React.useEffect(() => {
    if (questions && responses) {
      const finished = questions?.map(question => {
        const stepResponses = responses?.filter(response => response.question.step === question.step)
        return stepResponses.findIndex(response => response.question.position === question.position)
      }).indexOf(-1) < 0
      setStepFinished(finished)
    }
  }, [questions, stepFinished, responses])

  const handleReturn = (hasFinished) => {
    if (hasFinished && location.state?.fromOnBoarding) {
      next()
    }
    navigate(dashboardPath)
  }

  const goTo = (action = 'previous', calculate = false) => {
    const newStep = action === 'next' ? step + 1 : step - 1
    setStep(newStep)
    if (calculate) {
      calculateIndicator.trigger()
    }
    window.scrollTo(0, 90)
  }

  return (
    !features.RESILIENCE_INDICATOR && !getUser.isWorking
      ? <Forbidden />
      : !getUser.isWorking
        ? (
          <div className={classes.root}>
            {isIntroModalOpen && (
              <Dialog
                open={isIntroModalOpen}
                classes={{ paperWidthSm: classes.modal }}
                spacing={3}
              >
                <ModalQuestionnaire
                  title='Resilience Dynamic® Indicator'
                  subtitle={'Welcome to the Resilience Engine\'s leading resilience and wellbeing evaluation tool.'}
                  text={'Explore your own resilience: discover the dynamic nature of your own resilience level, how it varies between different contexts, and consider how to put into practice the Resilience Engine\'s recommendations. Click on the button below to begin your Resilience Dynamic® Indicator. '}
                  nextLabel='Begin'
                  nextAction={() => setShowIntro(false)}
                  skipAction={handleReturn}
                />
              </Dialog>
            )}
            <Button className={classes.button} onClick={handleReturn} startIcon={<ArrowBackIcon />}>Return to dashboard</Button>
            {!indicatorSteps.length ? (<Loading />) : (
              <span>
                <Typography className={classes.title} variant='h4' color='secondary'>{title}</Typography>
                <Typography className={classes.description}>{subtitle}</Typography>
                <SurveyTimeline steps={indicatorSteps} step={step} />
                {indicatorSteps && indicatorSteps[step]?.id !== 'complete' && (
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                )}
                {questions && questions.map(question => {
                  const answers = responses
                  const answer = answers
                    ? answers.find(ans => ans.question.type === question.type &&
                      ans.question.step === question.step &&
                      ans.question.position === question.position &&
                      parseInt(ans.cycle) === parseInt(user?.currentIndicatorCycle || 1))
                    : undefined

                  return (
                    <SurveyQuestion key={question.id} question={question} answer={answer} />
                  )
                })}
                {calculateIndicator.isWorking && (<Loading message='Calculating Results...' />)}
                {!calculateIndicator.isWorking && indicatorSteps[step].id !== 'complete' && (
                  <div className={classes.footer}>
                    <Typography className={classes.footerLink} variant='subtitle2' color='textPrimary'>
                      <Link onClick={handleReturn}>Skip for now</Link> and finish later
                    </Typography>
                    {indicatorSteps && indicatorSteps[step].id !== 'initial' && (
                      <Button className={classes.previousButton} color='secondary' variant='outlined' data-test='previous-indicator-btn' onClick={() => goTo('previous')}>
                        Previous section
                      </Button>
                    )}
                    {step < (indicatorSteps?.length - 2)
                      ? (<ReButton className={classes.footerButton} name='Next Section' dataTestId='next-indicator-btn' disabled={!stepFinished && step > 0} action={() => goTo('next')} />)
                      : (<ReButton className={classes.footerButton} name='Calculate score' dataTestId='calc-score-btn' disabled={!stepFinished} action={() => goTo('next', true)} />)}
                  </div>
                )}
                {!calculateIndicator.isWorking && indicatorSteps && indicatorSteps[step].id === 'complete' && result && (
                  <>
                    <IndicatorSurveyResult
                      score={result.score}
                      level={result.modelResult?.level}
                      high={result.averageHighScore}
                      low={result.averageLowScore}
                      variance={result.averageVariance}
                      levelKey={result.modelResult?.levelKey}
                      pdfLink={result.pdf}
                    />
                    <ReButton className={classes.footerButton} name='Finish Questionnaire' dataTestId='finish_questionnaire_btn' action={handleReturn} />
                  </>
                )}
              </span>
            )}
          </div>)
        : <>Loading...</>
  )
}

export default ReIndicator
