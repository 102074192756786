import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Grid } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useEventContext } from '../../contexts/event'
import { FullScreenToolbar, Overlay } from '../../components'
import { progressReducer } from '../../helpers/utils'
import { cicoSlice, userSlice } from '../../reducers'
import { CICOSteps, CICOStepsSkeletonList } from './cicoSteps'
import { CICOItems } from './cicoItems'
import CICOHeader from './header'
import CICOSubHeader from './subheader'
import { featureSelector } from '../../reducers/user/selectors'
import { Forbidden } from '../index'

const CheckinCheckout = () => {
  const params = useParams()
  const user = useSelector(userSlice.selectors.selectUser)
  const events = useEventContext()
  const { getUser } = useEventContext()
  const userId = useMemo(() => user.clientId && user.ssoId ? `${user.clientId}_${user.ssoId}` : null, [user])
  const [getCICOInfo] = useCorvusEventList([cicoSlice.eventBus.getInfo])
  const [CICOProgressWasUpdated] = useCorvusEventList([cicoSlice.eventBus.cicoProgressWasUpdated])
  const steps = useSelector(cicoSlice.selectors.selectSteps)
  const contents = useSelector(cicoSlice.selectors.selectContents)
  const info = useSelector(cicoSlice.selectors.selectInfo)
  const [fastOrDepth, setFastOrDepth] = useState('fast')
  const [selectedStep, setSelectedStep] = useState(null)
  const features = useSelector(featureSelector)

  const currentStepIndex = useMemo(() => {
    if (!selectedStep?.stepIndex) return null
    const current = steps.findIndex(s => s.stepIndex === selectedStep.stepIndex)
    return current
  }, [selectedStep?.stepIndex, steps])

  const loading = useMemo(() => getCICOInfo.isWorking || events.getUser.isWorking, [getCICOInfo.isWorking, events.getUser.isWorking])
  const allProgress = useMemo(() => {
    const contentsCopy = [...contents]
    const total = contentsCopy.reduce(progressReducer, 0)
    const percent = total / (contentsCopy.length * 100)
    return Math.round(percent * 100)
  }, [contents])

  const moveNextStep = useCallback(() => {
    setSelectedStep(steps[currentStepIndex + 1])
  }, [steps, currentStepIndex])

  // get cico info and progress on load
  useEventsOnViewLoad(() => {
    if (!getCICOInfo?.hasBeenTriggered) {
      getCICOInfo.trigger({ id: params.id })
    }
  }, [getCICOInfo])

  // register event listener
  useEffect(() => {
    if (userId && CICOProgressWasUpdated) {
      CICOProgressWasUpdated.trigger({ id: userId })
    }
  }, [userId, CICOProgressWasUpdated])

  // set fast or depth
  useEffect(() => {
    if (info && !info.hasFastTrack) {
      setFastOrDepth('depth')
    }
  }, [info])

  const renderList = () => {
    if (fastOrDepth === 'fast') {
      return loading ? <CICOStepsSkeletonList /> : (
        <CICOItems
          items={[...contents].filter(i => i.fastTrackOrder !== 0).sort((a, b) => a.fastTrackOrder - b.fastTrackOrder)}
          isLastStep
        />
      )
    }
    if (fastOrDepth === 'depth') {
      if (selectedStep !== null) {
        return (
          <CICOItems
            isLastStep={currentStepIndex === steps.length - 1}
            items={[...contents].filter(item => item.stepId === selectedStep.stepId).sort((a, b) => a.contentIndex - b.contentIndex)}
            moveNextStep={moveNextStep}
          />)
      }
      return loading ? <CICOStepsSkeletonList /> : <CICOSteps setSelectedStep={setSelectedStep} steps={[...steps].sort((a, b) => a.stepIndex - b.stepIndex)} contents={contents} />
    }
  }

  return (
    (!((features.FUNDAMENTALS && params.id === 'fundamentals') || (features.CHECKIN_CHECKOUT && params.id === 'cico-main')) && !loading && !getUser.isWorking)
      ? <Forbidden />
      : !getUser.isWorking
        ? <Overlay>
          <Grid container alignItems='center' justifyContent='center'>
            <FullScreenToolbar navigateTo='../../' />
            <Grid container marginX={5} width='80%' maxWidth={1280}>
              <CICOHeader
                title={info?.title}
                progress={allProgress || 0}
                selectedStep={selectedStep}
                backToSteps={() => setSelectedStep(null)}
                hasFastTrack={info?.hasFastTrack}
                fastOrDepth={fastOrDepth}
                setFastOrDepth={(v) => { setFastOrDepth(v); setSelectedStep(null) }}
                loading={loading}
              />
              <CICOSubHeader contents={contents} selectedStep={selectedStep} fastOrDepth={fastOrDepth} loading={loading} />
              <Grid container spacing={2}>
                {renderList()}
              </Grid>
            </Grid>
          </Grid>
        </Overlay>
        : <>Loading...</>
  )
}

export default CheckinCheckout
