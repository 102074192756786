import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { InformationalCard, ScoreQuestions, ScoreQuestionsHeader, AggregationHeader, OrganisationalDriversCard, Empty } from '../../components'
import { aggregationConfigs, userSlice } from '../../reducers'
import { useDispatch, useSelector } from 'react-redux'
import { getAreas } from '../../helpers/utils'
import { useDashboardContext } from '../../hooks'
import { useStyles } from './style'
import { featureSelector } from '../../reducers/user/selectors'
import { useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Forbidden } from '../index'
import { useEventContext } from '../../contexts/event'

const ResilienceEvaluation = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isOrgProfile } = useDashboardContext()
  const dashboardData = useSelector(userSlice.selectors.dashboardData)
  const [areas, setAreas] = useState([])
  const features = useSelector(featureSelector)
  const { getUser } = useEventContext()

  useEffect(() => {
    if (dashboardData.evaluationReportResult) {
      setAreas(getAreas(dashboardData.evaluationReportResult, true))
    }
  }, [dashboardData])

  const shouldShowEmpty = useMemo(() => {
    if (!Object.keys(areas).length || !dashboardData?.evaluationReportResult) return true
    return false
  }, [areas, dashboardData])

  useEventsOnViewLoad(() => {
    dispatch(aggregationConfigs.actions.setFilterType('resilience'))
  }, [dispatch])

  return (
    !features.ORGANISATION_DRIVERS && !getUser.isWorking ? <Forbidden />
      : !getUser.isWorking
        ? <div className={classes.root}>
          {isOrgProfile && <AggregationHeader />}
          {isOrgProfile && <OrganisationalDriversCard />}
          {!isOrgProfile &&
            <Grid item xs={11}>
              <Typography>Your Organisation Drivers shows the scores from the second part of your Resilience Dynamic® Questionnaire, which reflect the demand impact on you at work. These cover: Psychological Safety, Workload and Stress, Trust, and Engagement.</Typography>
            </Grid>}
          <Grid container spacing={2} marginTop={3}>
            <Grid item xs={11}>
              {!shouldShowEmpty && Object.keys(areas).map((name, i) => (
                <Grid key={i} marginBottom={2}>
                  <InformationalCard
                    title={areas[name].title}
                    description={areas[name].description}
                    subHeader={<ScoreQuestionsHeader average={areas[name].score} range={areas[name].range} />}
                  >
                    <ScoreQuestions questions={areas[name].questions} />
                  </InformationalCard>
                </Grid>
              ))}
              {shouldShowEmpty && <Empty />}
            </Grid>
          </Grid>
        </div> : <>Loading...</>
  )
}

export default ResilienceEvaluation
