import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ palette: { button, primary } }) => ({
  button: {
    borderColor: button.border,
    borderRadius: '7px',
    borderWidth: '2px',
    textTransform: 'none',
    padding: '10px 30px',
    minWidth: '145px',
    maxWidth: '220px',
    '&:hover': {
      backgroundColor: primary.main,
      color: '#FFF'
    },
    '&:disabled': {
      borderColor: 'rgba(202, 204, 207, 1)',
      backgroundColor: 'rgba(202, 204, 207, 1)',
      color: '#465270',
      cursor: 'not-allowed'
    }
  },
  tiny: {
    minWidth: '100px',
    maxWidth: '100px',
    width: '100px',
    height: '30px',
    fontSize: '12px',
    padding: '5px 5px'
  },
  small: {
    padding: '5px 30px',
    minWidth: '50px',
    maxWidth: '60px',
    fontSize: '12px',
    fontWeight: 'bold'
  },
  medium: {
    padding: '6px 42px',
    minWidth: '60px',
    maxWidth: '70px',
    fontSize: '14px'
  },
  customizable: {
    backgroundColor: props => props.bgColor ? props.bgColor : button.default,
    color: props => props.color ? props.color : button.color,
    fontSize: props => props.fontSize ? props.fontSize : '14px',
    fontWeight: props => props.fontWeight ? props.fontWeight : 'bold',
    borderRadius: props => props.rounded ? '25px' : '7px',
    borderWidth: props => props.btBorderWidth ? props.btBorderWidth : button.default,
    borderColor: props => props.btBorderColor ? props.btBorderColor : button.border
  }
}))
