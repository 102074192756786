import { Button, Grid, Paper, Tab, Tabs, Typography } from '@mui/material'
import React, { createContext, useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import uuid from 'uuid/v4'
import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import moment from 'moment'
import StepOne from './step-one'
import StepTwo from './step-two'
import StepThree from './step-three'
import { useStyles } from './style'
import { cohortSlice, userSlice } from '../../../reducers'
import { usePromiseEvent } from '../../../hooks'
import useNavigation from '../../../helpers/useNavigation'

export const EditCohortContext = createContext({})
const EditCohort = () => {
  const classes = useStyles()
  const navigate = useNavigation()
  const [step, setStep] = React.useState(0)
  const params = useParams()
  const dispatch = useDispatch()

  const [featureListWasRequested] = useCorvusEventList([cohortSlice.eventBus.getFeatures])
  const [licenseListWasRequested] = useCorvusEventList([cohortSlice.eventBus.getLicenses])
  const cohortWasCreated = usePromiseEvent('Cohort Was Created')
  const cohortWasRequested = usePromiseEvent('Cohort Was Requested')
  const cohortInfoWasRequested = usePromiseEvent('Cohort Info Was Requested')
  const cohortStatusWasChanged = usePromiseEvent('Cohort Status Was Changed')
  const cohortCoworkerWasBatched = usePromiseEvent('Cohort Coworker Was Batched')
  const inviteWasDeleted = usePromiseEvent('Invite Was Deleted')
  const inviteListWasRequested = usePromiseEvent('Invite List Was Requested')
  const cohortFeatureEntryWasAdded = usePromiseEvent('Cohort Feature Entry Was Added')
  const cohortFeatureEntryWasRemoved = usePromiseEvent('Cohort Feature Entry Was Removed')
  const cohortWasFinished = usePromiseEvent('Cohort Was Finished')
  const cohortNameWasUpdated = usePromiseEvent('Cohort Name Was Updated')
  const { id: tenantKey } = useSelector(userSlice.selectors.selectedView)

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      cohortName: '',
      originalCohortName: '',
      cohortId: '',
      originalCohortId: '', // used to update cohort name
      licenseId: '',
      description: '',
      invites: [],
      entries: []
    }
  })

  const renderStep = useCallback(() => {
    switch (step) {
      case 0:
        return <StepOne />
      case 1:
        return <StepTwo />
      case 2:
        return <StepThree />
      default:
        return <Typography>Unknown page</Typography>
    }
  }, [step])

  useEventsOnViewLoad(() => {
    licenseListWasRequested.trigger({ tenantKey })
  }, [licenseListWasRequested, tenantKey])

  useEventsOnViewLoad(async () => {
    if (params.cohortId && !cohortInfoWasRequested.event.hasBeenTriggered) {
      methods.setValue('cohortId', params.cohortId)
      const result = await cohortInfoWasRequested.promise({ cohortId: params.cohortId, tenantKey })
      dispatch(cohortSlice.actions.setLicenses(result.licenses))
      dispatch(cohortSlice.actions.setFeatures(result.features))
      methods.setValue('cohortName', result.cohort.name)
      methods.setValue('originalCohortName', result.cohort.name)
      methods.setValue('licenseId', result.cohort.licenseId)
      methods.setValue('invites', result.invites)
      const entries = result.entries.map((entry, idx) => ({
        id: uuid(),
        entryId: entry.sk,
        start_time: moment(entry.start),
        end_time: moment(entry.end),
        group: result.features.findIndex((feat) => feat.pk === entry.featureId) + 1,
        ...entry
      }))

      methods.setValue('entries', entries)
    }
  }, [params, methods, cohortInfoWasRequested])

  const cohortCreated = !!methods.watch('cohortId').length
  const cohortLoading = cohortInfoWasRequested.event.isWorking

  return (
    <div>
      <Grid container marginBottom={3} gap={1.5} flexDirection='column' alignItems='flex-start'>
        <Button color='secondary' size='small' startIcon={<img src='/icons/left-arrow.svg' alt='go back arrow' />} onClick={() => navigate('/dashboard/cohorts')}>Back to list</Button>
        <Typography variant='h4'>{methods.watch('originalCohortName')}</Typography>
      </Grid>
      <Paper className={classes.container}>
        <Tabs indicatorColor='secondary' textColor='secondary' value={step} onChange={(_e, v) => setStep(v)}>
          <Tab label='Basic Information' disabled={!cohortCreated} />
          <Tab label='Participants' disabled={!cohortCreated || cohortLoading} />
          <Tab label='Feature settings' disabled={!cohortCreated || cohortLoading} />
        </Tabs>
        <EditCohortContext.Provider
          value={{
            setStep,
            cohortWasCreated,
            featureListWasRequested,
            licenseListWasRequested,
            cohortFeatureEntryWasAdded,
            cohortFeatureEntryWasRemoved,
            cohortStatusWasChanged,
            cohortCoworkerWasBatched,
            inviteWasDeleted,
            inviteListWasRequested,
            cohortWasFinished,
            cohortWasRequested,
            cohortInfoWasRequested,
            cohortNameWasUpdated
          }}
        >
          <FormProvider {...methods}>
            <form>
              {renderStep()}
            </form>
          </FormProvider>
        </EditCohortContext.Provider>
      </Paper>
    </div>
  )
}

export default EditCohort
