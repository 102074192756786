import { configureStore } from '@reduxjs/toolkit'
import login from './login'
import user from './user'
import survey from './survey'
import tracker from './tracker'
import metrics from './metrics'
import content from './content'
import admin from './admin'
import settings from './settings'
import cohort from './cohort'
import client from './client'
import indicator from './indicator'
import cico from './cico'
import aggregationConfigs from './aggregationConfigs'
import userOnboarding from './userOnboarding'
import clientConfig from './clientConfig'

export default configureStore({
  reducer: {
    [login.name]: login.reducer,
    [user.name]: user.reducer,
    [survey.name]: survey.reducer,
    [tracker.name]: tracker.reducer,
    [metrics.name]: metrics.reducer,
    [content.name]: content.reducer,
    [admin.name]: admin.reducer,
    [settings.name]: settings.reducer,
    [indicator.name]: indicator.reducer,
    [cohort.name]: cohort.reducer,
    [clientConfig.name]: clientConfig.reducer,
    [client.name]: client.reducer,
    [cico.name]: cico.reducer,
    [aggregationConfigs.name]: aggregationConfigs.reducer,
    [userOnboarding.name]: userOnboarding.reducer
  }
})
